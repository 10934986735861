import PropTypes from 'prop-types';
import React from 'react';

import styles from './private-invitation-info.module.scss';
import Button from './Button';
import { fullName } from '../helpers/user_helpers';

export default function PrivateInvitationInfo(props) {
  const { invitedUser, onNotYou } = props;
  const name = fullName(invitedUser);

  return (
    <div className={styles['private-invitation']}>
      Private invite for {name}
      <span>
        ,&nbsp;
        <Button type="text" className="text-decoration-none" onClick={onNotYou}>
          Not you?
        </Button>
      </span>
    </div>
  );
}

PrivateInvitationInfo.propTypes = {
  invitedUser: PropTypes.object,
  onNotYou: PropTypes.func.isRequired,
};
