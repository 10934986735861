import _ from 'lodash';
import {
  ADD_COMMENT,
  APPEND_COMMENTS,
  DESTROY_COMMENT,
  RESET_COMMENTS,
  UPDATE_COMMENT,
} from './actionType';

export const initComments = {
  activePost: {},
  comments: [],
  lastCommentTimestamp: null,
};

const combineComments = (state, newComments) => {
  const { comments } = state;
  return _.uniqBy(_.concat(comments, newComments), 'id');
};

const getLastMessageTimestamp = comments => {
  const minDateMessage = _.minBy(comments, comment => comment.timestamp);
  return minDateMessage.timestamp;
};

const removeComment = (state, params) => {
  const { comments } = state;
  const { comment } = params;
  if (_isMatchComments(state, comment)) {
    return comments.filter(item => item.id !== comment.id);
  }
  return comments;
};

const updateComment = (state, params) => {
  const { comments } = state;
  const { comment } = params;

  if (_isMatchComments(state, comment)) {
    return comments.map(item => {
      if (item.id === comment.id) {
        return comment;
      }
      return item;
    });
  }
  return comments;
};

const _isMatchComments = (state, comment) => {
  const { activePost: post } = state;
  return post.room_id === comment.room_id;
};

const reducer = (state, action) => {
  const { type, params } = action;

  switch (type) {
    case APPEND_COMMENTS:
      const comments = combineComments(state, params);
      const lastCommentTimestamp = getLastMessageTimestamp(comments);
      const { lastCommentTimestamp: existingMinComment } = state;

      return {
        ...state,
        comments,
        lastCommentTimestamp,
        hasMore: existingMinComment !== lastCommentTimestamp,
      };
    case RESET_COMMENTS:
      return { ...initComments, activePost: params };
    case ADD_COMMENT:
      const { comment } = params;
      if (_isMatchComments(state, comment)) {
        const newComments = combineComments(state, [comment]);
        return { ...state, comments: newComments };
      }
      return state;
    case DESTROY_COMMENT:
      const updatedComments = removeComment(state, params);
      return { ...state, comments: updatedComments };
    case UPDATE_COMMENT:
      return { ...state, comments: updateComment(state, params) };
    default:
      return state;
  }
};

export default reducer;
