import {
  UPDATE_GLOBAL_CONTEXT,
  LOGOUT,
  HIDE_FLOATING_BUTTON,
} from './actionType';

export function updateGlobalContext(params) {
  return {
    type: UPDATE_GLOBAL_CONTEXT,
    params,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function hideFloatingButton() {
  return {
    type: HIDE_FLOATING_BUTTON,
  };
}
