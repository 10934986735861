import { HIDE_JOIN_MODAL, SHOW_JOIN_MODAL } from './actionType';

export const joinInit = {
  showJoinModal: false,
};

const reducer = (state = joinInit, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_JOIN_MODAL:
      return { ...state, showJoinModal: true };
    case HIDE_JOIN_MODAL:
      return { ...state, showJoinModal: false };
    default:
      return state;
  }
};

export default reducer;
