import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styles from './post-box-editor.module.scss';
import PhotoIcon from '../../assets/icon/photo_icon.svg';
import GifIcon from '../../assets/icon/gif_icon.svg';
import LinkIcon from '../../assets/icon/link_icon.svg';
import LocationIcon from '../../assets/icon/location_icon.svg';
import MentionIcon from '../../assets/icon/icon_mention.svg';
import ReactTooltip from 'react-tooltip';
import Button from '../Button';

const source = [
  {
    imgSrc: MentionIcon,
    description: 'Coming soon! Get the app to use this feature',
  },
  {
    imgSrc: PhotoIcon,
    description: 'Coming soon! Get the app to use this feature',
  },
  {
    imgSrc: GifIcon,
    description: 'Coming soon! Get the app to use this feature',
  },
  {
    imgSrc: LinkIcon,
    description: 'Coming soon! Get the app to use this feature',
  },
  {
    imgSrc: LocationIcon,
    description: 'Coming soon! Get the app to use this feature',
  },
];

export default function PostBoxOperationBar(props) {
  const { className } = props;

  return (
    <div className={ClassNames(styles['operation-wrapper'], className)}>
      {source.map((item, index) => {
        return (
          <div className="pr-2 pr-sm-3" key={index}>
            <img
              data-tip={true}
              data-for={item.imgSrc}
              className={ClassNames(`${styles['icon']}`)}
              alt={'LinkIcon'}
              src={item.imgSrc}
            />

            <ReactTooltip
              id={item.imgSrc}
              border
              clickable={true}
              effect={'solid'}
              className={styles['tooltip']}
              scrollHide={true}
              eventOff={'scroll'}
              globalEventOf={'scroll'}
            >
              <Button
                wrapperElement="a"
                target="_Blank"
                className={styles['button']}
                href="https://go.staging.hbnb.io/s4G2ll5XnY"
              >
                {item.description}
              </Button>
            </ReactTooltip>
          </div>
        );
      })}
    </div>
  );
}

PostBoxOperationBar.propTypes = {
  className: PropTypes.string,
};
