import React, { useReducer, useContext } from 'react';

import reducer, { hudInit } from './reducer';

const Context = React.createContext();

const Provider = props => {
  const [state, dispatch] = useReducer(reducer, hudInit);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useHudContext = () => useContext(Context);

export default { Context, Provider };
