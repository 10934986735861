import React, { useEffect, useRef, useState } from 'react';
import ClassNames from 'classnames';
import ClassName from 'classnames';

import { useMutation, useQuery } from 'react-apollo-hooks';
import { CURRENT_LOGIN_USER_QUERY } from '../../graphql/Authentication';
import { useError } from '../../hooks/ErrorHooks';
import styles from './top-bar.module.scss';
import { fullName } from '../../helpers/invitation_helpers';
import { useGlobalContext } from '../../store/global';
import { logout, updateGlobalContext } from '../../store/global/actions';

import QuestionIcon from '../../assets/icon/question_icon.svg';
import DoorIcon from '../../assets/icon/door_icon.svg';
import PowerIcon from '../../assets/icon/power_icon.svg';
import { LEAVE_COMMUNITY } from '../../graphql/Community';
import { useCommunityContext } from '../../store/community';
import { useHudContext } from '../../store/hud';
import { showHud } from '../../store/hud/actions';
import Avatar from '../Avatar';
import { getSpaceIconBackground } from '../../helpers/community_helpers';

export const useLeaveCommunity = ({ variables = {} }) => {
  const { dispatch: hudDispatch } = useHudContext();
  const { dispatch: globalDispatch } = useGlobalContext();

  const [leaveCommunity] = useMutation(LEAVE_COMMUNITY);

  const leaveCommunityHook = () => {
    leaveCommunity({ variables }).then(
      () => {
        globalDispatch(logout());
      },
      error => {
        const {
          networkError: { statusCode },
        } = error;
        // 422 means user already left the community, logout directly.
        if (statusCode === 422) {
          globalDispatch(logout());
        } else {
          hudDispatch(showHud());
        }
      }
    );
  };

  return leaveCommunityHook;
};

export default function AuthorizedUserStatus() {
  const { dispatch: globalDispatch } = useGlobalContext();
  const {
    state: { community },
  } = useCommunityContext();
  const { id: communityId } = community;

  const activeItemBorderColor = getSpaceIconBackground(community);

  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // loading current user information
  const { loading, error, data } = useQuery(CURRENT_LOGIN_USER_QUERY);
  useError(error);

  const leaveCommunity = useLeaveCommunity({ variables: { communityId } });

  useEffect(() => {
    if (data) {
      const {
        current_login_user: { user },
      } = data;

      globalDispatch(
        updateGlobalContext({ user_id: user.id, current_login_user: user })
      );
    }
    document.addEventListener('click', outDivClickHandler);
    return () => document.removeEventListener('click', outDivClickHandler);
  }, [data, globalDispatch]);

  const outDivClickHandler = e => {
    const target = e.target;
    if (
      dropdownRef &&
      dropdownRef.current &&
      !dropdownRef.current.contains(target)
    ) {
      setDropdown(false);
    }
  };

  if (loading) {
    return null;
  }

  const _logout = () => {
    globalDispatch(logout());
  };

  const _help = () => {
    window.open(process.env.REACT_APP_HELP_LINK);
  };

  const _leave = () => {
    leaveCommunity();
  };

  const list = [
    {
      src: QuestionIcon,
      text: 'Help',
      onClick: _help,
    },
    {
      src: DoorIcon,
      text: 'Leave Space',
      onClick: _leave,
    },
    {
      src: PowerIcon,
      text: 'Log Out',
      onClick: _logout,
    },
  ];

  const _onClick = item => {
    item.onClick();
  };

  const _showDropdown = () => {
    setDropdown(!dropdown);
  };

  const {
    current_login_user: { user },
  } = data;

  return (
    <>
      <span className={`d-none d-sm-inline-block mr-2 ${styles['username']}`}>
        {fullName({}, user)}
      </span>
      <div className={styles['avatar-wrapper']} ref={dropdownRef}>
        <Avatar
          size={42}
          url={user.avatar.small_url_2x}
          user={user}
          includeBorder={true}
          onClick={_showDropdown}
        />

        {dropdown && <div className={ClassName(styles['triangle'])} />}
        {dropdown && (
          <ul className={styles['wrapper']}>
            <li
              className={ClassNames(
                `${styles['label']} d-flex align-items-center `
              )}
            >
              Settings
            </li>
            {list.map(item => {
              return (
                <span key={item.text}>
                  <li
                    className={ClassNames(
                      `d-flex align-items-center ${styles['item']}`
                    )}
                    onClick={() => _onClick(item)}
                  >
                    <div
                      style={{ backgroundColor: activeItemBorderColor }}
                      className={styles['active-option']}
                    />

                    <img src={item.src} alt={item.text} />
                    <span className="ml-3">{item.text}</span>
                  </li>
                  {item.text === 'Leave Space' ? (
                    <div className={styles['border-bottom']} />
                  ) : null}
                </span>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}
