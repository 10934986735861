import React from 'react';
import PropTypes from 'prop-types';

import HobnobModal from '../HobnobModal';
import Button from '../Button';

import styles from './post-box-full-modal.module.scss';
import PostBoxEditor from './PostBoxEditor';
import SpaceSelect from '../SpaceSelect';

export default function PostBoxFullBoxModal(props) {
  const {
    show,
    onClose,
    newPost,
    updatePost,
    createdNewPost,
    showUnsavedChangesModal,
    options,
    onChange,
  } = props;

  return (
    <HobnobModal
      show={show}
      onClose={onClose}
      showCancelIcon={false}
      containerClassName={styles['modal-container']}
      contentClassName="d-flex flex-column h-100"
    >
      <PostBoxEditor
        newPost={newPost}
        updatePost={updatePost}
        needCollapse={true}
        onCollapse={onClose}
      />

      <div className="d-flex justify-content-between align-items-center p-3 bg-light border-top flex-wrap">
        <div className="flex-grow-1 text-left">
          <SpaceSelect
            placement={'top-end'}
            value={newPost.topicId}
            onChange={onChange}
            options={options}
            className={styles['selector']}
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            type={'text'}
            className={styles['cancel-button']}
            onClick={showUnsavedChangesModal}
          >
            cancel
          </Button>
          <Button
            disabled={!newPost.title || newPost.title === ''}
            className={styles['button']}
            onClick={createdNewPost}
          >
            Post
          </Button>
        </div>
      </div>
    </HobnobModal>
  );
}

PostBoxFullBoxModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  newPost: PropTypes.object,
  createdNewPost: PropTypes.func,
  showUnsavedChangesModal: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
};
