import { useEffect } from 'react';
import { useGlobalContext } from '../store/global';
import { updateGlobalContext } from '../store/global/actions';

/**
 * Process all apollo network and graphql error here.
 * 1. deal with 401 and 403 error
 * 2. deal with 503 error
 * 3. deal with other error code like 404
 * 4. process graphql error
 *
 * @param error
 */
export const useError = error => {
  const { state, dispatch } = useGlobalContext();

  useEffect(() => {
    const _processNetworkError = networkError => {
      if (networkError) {
        const { statusCode } = networkError;
        console.log(networkError);
        if (statusCode === 401 || statusCode === 403) {
          dispatch(updateGlobalContext({ isAuthorized: false }));
        } else if (statusCode === 503) {
          dispatch(updateGlobalContext({ isServiceUnavailable: true }));
        } else {
          dispatch(updateGlobalContext({ isError: true }));
        }
      }
    };

    const _processGraphQLErrors = graphQLErrors => {
      if (graphQLErrors && graphQLErrors.length) {
        if (_isUnauthorizedError(graphQLErrors)) {
          dispatch(updateGlobalContext({ isAuthorized: false }));
        } else {
          dispatch(
            updateGlobalContext({
              isError: true,
              errorMessage: 'An error occurred.',
            })
          );
        }
      }
    };

    const _isUnauthorizedError = graphQLErrors => {
      const unauthorizedErrors = graphQLErrors.filter(
        error => error.message === 'unauthorized'
      );

      return unauthorizedErrors && unauthorizedErrors.length > 0;
    };

    if (error) {
      const { networkError, graphQLErrors } = error;
      _processNetworkError(networkError);
      _processGraphQLErrors(graphQLErrors);
    }
  }, [error, state, dispatch]);
};
