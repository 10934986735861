import React from 'react';

import Header from './Header';
import DetailsContainer from './DetailsContainer';

function PostDetails() {
  return (
    <div className="w-100 d-flex flex-column flex-grow-1">
      <Header />
      <DetailsContainer />
    </div>
  );
}

export default PostDetails;
