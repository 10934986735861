import React, { useState } from 'react';
import ClassNames from 'classnames';

import styles from './user-status-header.module.scss';

import PrivateInvitationInfo from '../PrivateInvitationInfo';
import Button from '../Button';
import { useInvitationContext } from '../../store/invitation';
import { fullName } from '../../helpers/user_helpers';
import { useGlobalContext } from '../../store/global';
import { updateGlobalContext } from '../../store/global/actions';
import { useMutation } from 'react-apollo-hooks';
import { DECLINE_INVITATION_MUTATION } from '../../graphql/Invitation';
import { useHudContext } from '../../store/hud';
import { showHud } from '../../store/hud/actions';
import TokenizedJoinModal from '../modals/TokenizedJoinModal';

export default function UserStatusHeader() {
  const { dispatch: globalDispatch } = useGlobalContext();
  const { dispatch: hudDispatch } = useHudContext();
  const { state: invitation } = useInvitationContext();
  const [showJoinModal, setShowJoinModal] = useState(false);
  const {
    invitation: {
      invitation_by_token: {
        community_invitation: {
          id,
          community_id,
          created_by_user,
          user: invitedUser,
          updated_at,
        },
      },
    },
  } = invitation;
  const declineInvitationOptions = {
    variables: {
      communityId: community_id,
      invitationId: id,
      lastUpdatedAt: updated_at,
    },
  };

  const [declineInvitation] = useMutation(DECLINE_INVITATION_MUTATION);

  function _onNotYou() {
    globalDispatch(
      updateGlobalContext({
        switch_to_normal_mode: true,
      })
    );
  }

  function _noThanks() {
    declineInvitation(declineInvitationOptions).then(
      () => {
        globalDispatch(
          updateGlobalContext({
            switch_to_normal_mode: true,
          })
        );
      },
      error => {
        const {
          networkError: { statusCode },
        } = error;
        if (statusCode !== 422) {
          hudDispatch(showHud());
        } else {
          globalDispatch(
            updateGlobalContext({
              switch_to_normal_mode: true,
            })
          );
        }
      }
    );
  }

  return (
    <div className="h-100 d-flex justify-content-between justify-content-sm-end align-items-center">
      <div className={styles['info-wrapper']}>
        <strong className={styles['text-wrapper']}>
          {fullName(created_by_user)} invited you!
        </strong>
        {invitation ? (
          <PrivateInvitationInfo
            invitedUser={invitedUser}
            onNotYou={_onNotYou}
          />
        ) : null}
      </div>
      <div className={styles['btn-wrapper']}>
        <Button
          type="button"
          className={ClassNames(`d-inline ${styles['button']}`)}
          onClick={() => setShowJoinModal(true)}
        >
          Join
        </Button>
        <Button
          type="button"
          filled={false}
          className={ClassNames(`d-inline ${styles['button']}`)}
          onClick={_noThanks}
        >
          No, thanks
        </Button>
      </div>
      {/* Reduce to render the join modal when show this page */}
      {showJoinModal ? (
        <TokenizedJoinModal onClose={() => setShowJoinModal(false)} />
      ) : null}
    </div>
  );
}
