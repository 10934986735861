import gql from 'graphql-tag';

export const INVITATION_BY_TOKEN_QUERY = gql`
  query InvitationByToken($communityId: String!, $invitationToken: String!) {
    invitation_by_token(
      communityId: $communityId
      invitationToken: $invitationToken
    )
      @rest(
        type: "InvitationByToken"
        method: "GET"
        path: "/communities/{args.communityId}/invitations/{args.invitationToken}"
      ) {
      community_invitation @type(name: "CommunityInvitation") {
        id
        community_id
        first_name
        last_name
        join_state
        updated_at
        delivery_state
        created_by_user @type(name: "InviteCreatedUser") {
          id
          first_name
          last_name
          verified
          avatar @type(name: "InviteCreatedUserAvatar") {
            small_url_2x
            medium_url_2x
          }
        }
        user @type(name: "InvitedUser") {
          id
          first_name
          last_name
          verified
          avatar @type(name: "InvitedUserAvatar") {
            small_url_2x
            medium_url_2x
          }
        }
      }
    }
  }
`;

export const VIEW_INVITATION_QUERY = gql`
  query ViewInvitationUpdate(
    $communityId: UUID!
    $invitationId: UUID!
    $lastUpdatedAt: String!
  ) {
    view_invitation_update(
      communityId: $communityId
      invitationId: $invitationId
      input: { community_invitation: { last_updated_at: $lastUpdatedAt } }
    )
      @rest(
        type: "ViewInvitationUpdate"
        method: "POST"
        path: "/communities/{args.communityId}/invitations/{args.invitationId}/view"
      ) {
      community_invitation @type(name: "CommunityInvitation") {
        id
        join_state
        viewed_at
        delivery_state
      }
    }
  }
`;

export const DECLINE_INVITATION_MUTATION = gql`
  mutation DeclineInvitation(
    $communityId: UUID!
    $invitationId: UUID!
    $lastUpdatedAt: String!
  ) {
    decline_invitation(
      communityId: $communityId
      invitationId: $invitationId
      input: { community_invitation: { last_updated_at: $lastUpdatedAt } }
    )
      @rest(
        type: "DeclineInvitation"
        method: "POST"
        path: "/communities/{args.communityId}/invitations/{args.invitationId}/decline"
      ) {
      community_invitation @type(name: "CommunityInvitation") {
        id
        join_state
        viewed_at
        delivery_state
      }
    }
  }
`;
