import { onError } from 'apollo-link-error';
import { Observable } from 'apollo-link';
import { USER_REFRESH_TOKEN } from './AuthLink';
import { clearTokens, setLoginTokens } from '../helpers/localstorage_helpers';

/**
 * Common place to deal with the GraphQL errors and network error.
 *
 * Refer to: https://www.apollographql.com/docs/link/links/error/
 */

const ErrorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.error('GraphQL Errors: ', graphQLErrors);
    }

    if (networkError) {
      console.error('Network Error: ', networkError);
      const { statusCode } = networkError;
      if (statusCode === 403) {
        const promise = getAccessTokenByRefreshToken();

        return promiseToObservable(promise).flatMap(res => {
          const { needLogin, access_token } = res;
          if (!needLogin) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${access_token}`,
              },
            });

            setLoginTokens(res);
          }

          // retry the request
          if (access_token) {
            return forward(operation);
          } else {
            // if the new access token is empty, return the origin network error
            return new Observable(subscriber => subscriber.error(networkError));
          }
        });
      }
    }
  }
);

const getAccessTokenByRefreshToken = async () => {
  const refreshToken = localStorage.getItem(USER_REFRESH_TOKEN);

  if (refreshToken) {
    const request = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/oauth/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem(USER_REFRESH_TOKEN),
        }),
      }
    );

    if (request.status === 200) {
      const { access_token, refresh_token } = await request.json();
      return { needLogin: false, access_token, refresh_token };
    } else if (request.status >= 400 && request.status < 500) {
      // obtain new access token failed due to refresh token expired.
      clearTokens();
      return { needLogin: true };
    } else {
      // service is not available
      return { needLogin: true };
    }
  } else {
    // obtain new access token failed due to missing refresh token.
    clearTokens();
    return { needLogin: true };
  }
};

const promiseToObservable = promise => {
  return new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;

        subscriber.next(value);
        subscriber.complete();
      },
      error => subscriber.error(error)
    );
  });
};

export default ErrorLink;
