import { ApolloLink } from 'apollo-link';
import HttpLink from './HttpLink';
import ErrorLink from './ErrorLink';
import AuthLink from './AuthLink';
import RestApiLink from './RestApiLink';

/**
 * Combine all links into `ApolloLink` for client using.
 *
 * Refer to: https://www.apollographql.com/docs/link/
 */

const Link = ApolloLink.from([AuthLink, ErrorLink, RestApiLink, HttpLink]);

export default Link;
