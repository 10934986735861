import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'classnames';

import { hideFloatingButton } from '../store/global/actions';
import { useGlobalContext } from '../store/global';
import { useCommunityContext } from '../store/community';

import styles from './floating-button-container.module.scss';

import Cancel from '../assets/icon/cancel_white_icon.svg';

export default function FloatingButtonContainer(props) {
  const {
    state: { floatingButtonVisible },
    dispatch,
  } = useGlobalContext();

  const {
    state: { community },
  } = useCommunityContext();
  const { open_in_app_url } = community;

  const _renderButton = () => {
    const { children } = props;

    return (
      <div className={styles['button-container']}>
        <span className={ClassNames(`${styles['fb-cta']} pl-4 pr-3`)}>
          <a
            className={styles['link']}
            target={'_blank'}
            href={open_in_app_url}
          >
            Open in App
          </a>
        </span>
        {children || (
          <span
            className={ClassNames(`${styles['close-button']} pl-3 pr-3`)}
            onClick={_onClose}
          >
            <img src={Cancel} alt={'cancel'} />
          </span>
        )}
      </div>
    );
  };

  const _onClose = () => {
    dispatch(hideFloatingButton());
  };
  const { style } = props;

  return (
    <div
      className={ClassNames(
        `${styles['container']} ${
          floatingButtonVisible ? styles['is-visible'] : styles['is-hide']
        } d-block d-sm-none`
      )}
      style={style}
    >
      {_renderButton()}
    </div>
  );
}

FloatingButtonContainer.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
};
