import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './topic-filter-container.module.scss';

import { useCommunityContext } from '../../store/community';
import {
  setCurrentActivePost,
  setTopicIds,
} from '../../store/community/actions';
import TopicFilterItems from '../TopicFilterItems';
import { BREAKPOINT_LG } from '../../consts';
import { useWindowSize } from '../../hooks/WindowHooks';
import { useCommentsContext } from '../../store/comments';
import { resetComments } from '../../store/comments/actions';

function TopicFilterContainer({ topics, toggleSideBar }) {
  const { state, dispatch } = useCommunityContext();
  const { dispatch: commentDispatch } = useCommentsContext();
  const { topicIds: topic_ids, community } = state;
  const width = useWindowSize().width;

  const onClickTopicItem = value => {
    let topicIds = JSON.parse(JSON.stringify(topic_ids));
    const index = topicIds.indexOf(value);

    if (index === -1) {
      topicIds = [value];
    } else {
      topicIds.splice(index, 1);
    }
    dispatch(setTopicIds(topicIds));
    dispatch(setCurrentActivePost({}));
    commentDispatch(resetComments());
    if (width < BREAKPOINT_LG) {
      toggleSideBar();
      window.scrollTo(0, 0);
    }
  };

  const _onClickAllTopics = () => {
    if (JSON.stringify(topic_ids) !== '[]') {
      dispatch(setTopicIds([]));
    }
    if (width < BREAKPOINT_LG) {
      toggleSideBar();
      window.scrollTo(0, 0);
    }
  };

  return (
    <React.Fragment>
      <ul className={styles['wrapper']}>
        <li
          className={ClassNames(
            `${styles['header']} border-bottom d-flex align-items-center`
          )}
          onClick={_onClickAllTopics}
        >
          Posts
        </li>
      </ul>

      <TopicFilterItems
        topics={topics}
        onClick={onClickTopicItem}
        currentActives={topic_ids}
        community={community}
      />
    </React.Fragment>
  );
}

TopicFilterContainer.propsTypes = {
  topics: PropTypes.array,
  toggleSideBar: PropTypes.array,
};

export default TopicFilterContainer;
