import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import LinkPreview from './LinkPreview';
import Gallery from './Gallery';

import styles from './message.module.scss';
import TextMessage from './TextMessage';
import LocationBody from './LocationBody';

export default function Message({ source }) {
  const { message_parts } = source;
  return (
    <React.Fragment>
      {message_parts.map(item => {
        const { type, body, link_previews, metadata } = item;
        switch (type) {
          case 'text':
            return (
              <div key={item.id}>
                <TextMessage body={body} linkPreviews={link_previews} />
              </div>
            );
          case 'link':
            return link_previews.map((item, index) => {
              return <LinkPreview key={index} linkPreview={item} />;
            });
          case 'giphy':
            return (
              <img
                key={item.id}
                className={ClassNames(`mt-3 ${styles['message-gif']}`)}
                src={metadata.url_medium}
                alt="gif"
              />
            );
          case 'gallery':
            return <Gallery key={item.id} metadata={metadata} />;
          case 'location':
            return (
              <LocationBody className="mt-3" key={item.id} comment={item} />
            );
          default:
            return null;
        }
      })}
    </React.Fragment>
  );
}

Message.propTypes = {
  source: PropTypes.object,
};
