import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../Avatar';

import styles from './recent-participants-preview.module.scss';

const MAX_NUM_INVITATIONS = 4;

export default function RecentParticipantsPreview(props) {
  const { users, onClick } = props;

  let numToShow = users.length;

  if (numToShow > MAX_NUM_INVITATIONS) {
    numToShow = MAX_NUM_INVITATIONS;
  }

  const usersToShow = users.slice(0, numToShow);

  const _renderInvitationAvatar = user => {
    return (
      <Avatar
        className={styles['avatar']}
        includeBorder={false}
        key={user.id}
        user={user}
        size={20}
      />
    );
  };

  return (
    <div className={styles['container']}>
      <div className={styles['avatars-container']} onClick={onClick}>
        {usersToShow.map(_renderInvitationAvatar)}
        {numToShow > MAX_NUM_INVITATIONS ? (
          <Avatar
            className={styles['avatar']}
            includeBorder={false}
            size={20}
            user={{}}
            textOverride={`+${numToShow - MAX_NUM_INVITATIONS}`}
          />
        ) : null}
      </div>
    </div>
  );
}

RecentParticipantsPreview.propTypes = {
  currentInvitation: PropTypes.object,
  users: PropTypes.array,
  onClick: PropTypes.func,
  invitations: PropTypes.array,
};
