import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactionIcon from '../assets/icon/reaction.svg';
import { pluralize } from '../helpers/utils';
import { ADD_REACTION, DESTROY_REACTION } from '../services/room_channel_types';
import 'emoji-mart/css/emoji-mart.css';
import EmojiData from 'emoji-mart/data/apple.json';
import { Emoji, NimblePicker } from 'emoji-mart';
import ReactTooltip from 'react-tooltip';
import ClassNames from 'classnames';
import styles from './reactions.module.scss';
import { useGlobalContext } from '../store/global';
import { push } from '../store/socket/reducer';
import _ from 'lodash';
import emojiImage from '../assets/emoji/64.png';
import HobnobModal from './HobnobModal';

import { useWindowSize } from '../hooks/WindowHooks';
import { BREAKPOINT_SM } from '../consts';

const _calculateReactionCount = reactions => {
  return reactions
    .map(reaction => {
      const { user_ids } = reaction;
      return user_ids.length;
    })
    .reduce((a, b) => a + b, 0);
};

const Reactions = props => {
  const {
    channel: channelName,
    itemKey,
    message_id,
    showEmoji,
    reactions = [],
    onReactionUpdate,
    className,
    uniqueId,
  } = props;

  const {
    state: { user_id: current_user_id },
  } = useGlobalContext();
  const reactionCount = _calculateReactionCount(reactions);
  const tooltipRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const innerWidth = useWindowSize().width;

  const _addReaction = emoji => {
    let updatedReactions = reactions;

    const existingSlugs = reactions.filter(
      reaction => reaction.slug === emoji.id
    );
    if (existingSlugs && existingSlugs.length === 1) {
      // exist the slug and add the current user id in.
      updatedReactions = reactions.map(reaction => {
        if (reaction.slug === emoji.id) {
          // update user ids
          const { user_ids } = reaction;
          const uniqueUserIds = _.uniq([...user_ids, current_user_id]);
          reaction.user_ids = uniqueUserIds;
        }
        return reaction;
      });
    } else if (existingSlugs && existingSlugs.length === 0) {
      // does not exist the slug, and then create a new reaction
      // then add into the reactions
      updatedReactions = [
        ...reactions,
        { slug: emoji.id, user_ids: [current_user_id] },
      ];
    }

    onReactionUpdate(itemKey, updatedReactions);
  };

  const _selectAddEmoji = emoji => {
    _addReaction(emoji);

    push({
      channel: channelName,
      action: ADD_REACTION,
      payload: { message_id, slug: emoji.id },
      errorCallback: error => {
        if (error) {
          const { slug } = error.errors;
          // do not remove the reaction if the user re-selects from the emoji panel.
          if (slug.indexOf('has already been taken') === -1) {
            _removeReaction(emoji);
          }
        }
      },
    });

    // https://github.com/wwayne/react-tooltip/issues/449#issuecomment-483275613
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.tooltipRef = null;
      ReactTooltip.hide();
      setShowEmojiPicker(false);
    }

    setShowModal(false);
  };

  const _removeReaction = emoji => {
    const updatedReactions = reactions
      .map(reaction => {
        if (reaction.slug === emoji.id) {
          // remove the currently user id from this slug
          const { user_ids } = reaction;
          const index = user_ids.indexOf(current_user_id);
          if (index !== -1) {
            user_ids.splice(index, 1);
            reaction.user_ids = user_ids;
          }
        }

        return reaction;
      })
      .filter(reaction => reaction.user_ids.length > 0);

    onReactionUpdate(itemKey, updatedReactions);
  };

  const _destroyReaction = emoji => {
    _removeReaction(emoji);

    push({
      channel: channelName,
      action: DESTROY_REACTION,
      payload: { message_id, slug: emoji.id },
      errorCallback: () => _addReaction(emoji),
    });
  };

  const _clickDisplayedEmoji = emoji => {
    const userAddedReactions = reactions.filter(
      reaction =>
        reaction.user_ids.indexOf(current_user_id) !== -1 &&
        reaction.slug === emoji.id
    );

    if (userAddedReactions.length === 1) {
      _destroyReaction(emoji);
    } else {
      _selectAddEmoji(emoji);
    }
  };

  const _renderReactions = () => {
    return reactions
      .filter(reaction => reaction.user_ids.length > 0)
      .map(reaction => (
        <div
          key={reaction.slug}
          className={ClassNames(
            `${styles['emoji-wrapper']} ${reaction.user_ids.indexOf(
              current_user_id
            ) !== -1 && styles['added_reaction']}`
          )}
          onClick={() => _clickDisplayedEmoji({ id: reaction.slug })}
        >
          <Emoji
            key={reaction.slug}
            emoji={reaction.slug}
            size={18}
            backgroundImageFn={() => emojiImage}
          />
          &nbsp;{reaction.user_ids.length}
        </div>
      ));
  };

  const _renderReactionCount = () => {
    return (
      <>
        <span>&nbsp;{reactionCount}&nbsp;</span>
        <span className="d-none d-xl-inline-block">
          {pluralize(reactionCount, 'Reaction')}
        </span>
      </>
    );
  };

  const _renderEmojiPicker = () => {
    if (showEmojiPicker) {
      return (
        <NimblePicker
          data={EmojiData}
          onSelect={_selectAddEmoji}
          showSkinTones={false}
          showPreview={false}
          backgroundImageFn={() => emojiImage}
        />
      );
    } else {
      // render an empty div to make sure the picker position is correct,
      // otherwise ReactTooltip will set the wrong position.
      return <div className={styles['empty-emoji-picker']}></div>;
    }
  };

  const containerStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    margin: '0',
    padding: '0',
    borderRadius: 0,
    minHeight: 'auto',
  };

  const _onClick = () => {
    if (innerWidth <= BREAKPOINT_SM) {
      setShowModal(true);
    }
  };

  // NimblePicker has a bad loading performance,
  // so only render it when the user clicks it.
  const _afterShow = () => {
    setShowEmojiPicker(true);
  };

  const _renderReactionPicker = () => {
    return (
      <ReactTooltip
        id={uniqueId}
        border
        clickable={true}
        effect={'solid'}
        type="light"
        globalEventOff="click"
        event={'click'}
        className={styles['tooltip']}
        ref={tooltipRef}
        afterShow={_afterShow}
      >
        {_renderEmojiPicker()}
      </ReactTooltip>
    );
  };

  return (
    <div className={ClassNames(showEmoji && styles['wrapper'], className)}>
      <div
        data-tip={true}
        data-for={uniqueId}
        onClick={_onClick}
        className={ClassNames(`${showEmoji && styles['inactive']}`)}
      >
        <img src={ReactionIcon} alt="add reaction" />
        {showEmoji ? null : _renderReactionCount()}
      </div>

      {showEmoji ? _renderReactions() : null}

      {innerWidth > BREAKPOINT_SM ? (
        showEmoji ? (
          _renderReactionPicker()
        ) : null
      ) : (
        <HobnobModal
          show={showModal}
          onClose={() => setShowModal(false)}
          showCancelIcon={false}
          containerStyles={containerStyle}
          contentClassName={styles['emoji-picker-modal']}
        >
          <NimblePicker
            data={EmojiData}
            onSelect={_selectAddEmoji}
            showSkinTones={false}
            showPreview={false}
            backgroundImageFn={() => emojiImage}
          />
        </HobnobModal>
      )}
    </div>
  );
};

Reactions.propTypes = {
  channel: PropTypes.string,
  itemKey: PropTypes.string,
  message_id: PropTypes.string,
  showEmoji: PropTypes.bool,
  reactions: PropTypes.array,
  onReactionUpdate: PropTypes.func,
  className: PropTypes.string,
  uniqueId: PropTypes.string,
};

export default Reactions;
