export function fullName(user) {
  let firstName = user.first_name;
  let lastName = user.last_name;

  let name;
  if (firstName) {
    name = firstName;
    if (lastName) {
      name += ` ${lastName}`;
    }
  } else {
    name = lastName;
  }
  return name;
}
