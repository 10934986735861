import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import TopBar from '../../components/content/TopBar';
import Content from '../../components/content/Content';

import ClassNames from 'classnames';
import styles from './home-page.module.scss';
import { COMMUNITY_BY_IDS } from '../../graphql/Community';
import { useQuery } from 'react-apollo-hooks';
import Loading from '../../components/Loading';

import { useCommunityContext } from '../../store/community';
import { useError } from '../../hooks/ErrorHooks';
import ErrorModal from '../../components/modals/ErrorModal';
import { useHudContext } from '../../store/hud';
import { hideHud } from '../../store/hud/actions';
import { requestCommunity } from '../../store/community/actions';
import { join } from '../../store/socket/reducer';

import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../consts';

/**
 * Deal with the responsive size when resize the window to
 * decide show or hide the sidebar.
 *
 * @param setShowSideBar
 */
const useResizeWindow = setShowSideBar => {
  const width = window.innerWidth;

  useEffect(() => {
    // show or hide side bar(column 1 & 2) based on the screen width
    if (width < BREAKPOINT_SM) {
      setShowSideBar(true);
    } else if (width >= BREAKPOINT_SM && width < BREAKPOINT_LG) {
      setShowSideBar(false);
    } else if (width >= 1200) {
      setShowSideBar(true);
    }
  }, [width, setShowSideBar]);
};

export default function HomePage() {
  const [showSideBar, setShowSideBar] = useState(true);

  const {
    state: { community: publicCommunity },
    dispatch: communityDispatch,
  } = useCommunityContext();
  const { state: hud, dispatch: hudDispatch } = useHudContext();

  useResizeWindow(setShowSideBar);

  function _toggleSideBar() {
    setShowSideBar(!showSideBar);
  }

  // query the community
  const options = {
    variables: {
      ids: [publicCommunity.id],
    },
    skip: !publicCommunity.id,
  };
  const { loading, error, data } = useQuery(COMMUNITY_BY_IDS, options);
  useError(error);

  // initial socket once the user logged in
  useEffect(() => {
    const _processQueryCommunity = data => {
      const { communities } = data;
      if (communities && communities.length === 1) {
        const activeCommunity = communities[0];
        communityDispatch(requestCommunity(activeCommunity));

        const { community_channel } = activeCommunity;
        if (community_channel) {
          // set the current login user as present in this community
          join(community_channel);
        }
      }
    };

    if (data) {
      _processQueryCommunity(data);
    }
  }, [data, communityDispatch]);

  if (loading || !data) {
    return <Loading />;
  }

  const { communities } = data;
  if (!communities || communities.length !== 1) {
    return <ErrorModal show={true} />;
  }

  const community = communities[0] || publicCommunity;

  const _onCloseErrorModal = () => {
    hudDispatch(hideHud());
  };

  return (
    <div className={ClassNames(`d-flex w-100 ${styles['container']}`)}>
      <div
        className={ClassNames(
          `${styles['mask']} ${
            showSideBar ? 'd-none d-sm-block d-lg-none' : 'd-none'
          }`
        )}
        onClick={_toggleSideBar}
      />

      <div
        className={ClassNames(
          `${styles['sideBar-wrapper']} border-right p-0 ${
            showSideBar ? 'd-flex' : 'd-none'
          } `
        )}
      >
        <Sidebar community={community} onClick={_toggleSideBar} />
      </div>

      <div
        className={ClassNames(
          `${styles['content-wrapper']} p-0 ${
            showSideBar ? 'd-none d-sm-flex' : 'd-flex'
          }`
        )}
      >
        <div className="w-100 d-flex flex-column">
          <TopBar onClick={_toggleSideBar} />
          <Content />
        </div>
      </div>

      <ErrorModal show={hud.showErrorModal} onClose={_onCloseErrorModal} />
    </div>
  );
}
