import React, { useReducer, useContext } from 'react';

import reducer, { globalContextInit } from './reducer';

const Context = React.createContext();

const Provider = props => {
  const [state, dispatch] = useReducer(reducer, globalContextInit);

  let component = props.children;

  return (
    <Context.Provider value={{ state, dispatch }}>{component}</Context.Provider>
  );
};

export const useGlobalContext = () => useContext(Context);

export default { Context, Provider };
