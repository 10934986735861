import React from 'react';
import ClassNames from 'classnames';

import styles from './PageNotFound.module.scss';
import logo from '../../assets/404.svg';

const PageNotFound = () => {
  return (
    <div className={styles['PageNotFound']}>
      <div className={ClassNames(styles['container'], 'bg-dark')}>
        <img src={logo} alt="404" width={300} />
        <p className={styles['error-code']}>Not Found</p>
      </div>
    </div>
  );
};

export default PageNotFound;
