import gql from 'graphql-tag';

export const COMMUNITY_BY_SLUG_QUERY = gql`
  query CommunityBySlug($slug: String!) {
    community_by_slug(slug: $slug) {
      id
      cover_image_url
      open_in_app_url
      name
      member_count
      post_count
      topic_count
      description
      landing_page_description
      community_initials
      avatar_url
      community_color {
        color_value
        custom_color_hexcode
      }
      my_community_member {
        community_initials
        community_color {
          color_value
          custom_color_hexcode
        }
      }
    }
  }
`;

export const COMMUNITY_BY_IDS = gql`
  query Communities($ids: [UUID]!, $topics: [UUID], $postLimit: Int) {
    communities(ids: $ids) {
      id
      community_channel
      cover_image_url
      open_in_app_url
      avatar_url
      name
      member_count
      post_count
      topic_count
      description
      social_link_twitter
      social_link_facebook
      social_link_instagram
      community_initials
      community_color {
        color_value
        custom_color_hexcode
      }
      my_community_member {
        community_initials
        community_color {
          color_value
          custom_color_hexcode
        }
      }
      community_members {
        id
        user_id
        first_name
        last_name
        avatar_url
        role
        joined_at
      }
      topics {
        id
        name
      }
      community_members {
        id
        user_id
        name
        first_name
        last_name
        avatar_url
        role
        joined_at
      }
      posts_connection(topic_ids: $topics, limit: $postLimit) {
        posts {
          id
          destroyed_at
          channel
          is_read
          last_read_at
          last_message {
            timestamp
          }
        }
        page_info {
          start_cursor
          end_cursor
          has_next_page
        }
      }
    }
  }
`;

export const QUERY_COMMUNITY_POSTS_BY_PAGE = gql`
  query PostList(
    $ids: [UUID]!
    $topics: [UUID]
    $postsLimit: Int
    $before: String
  ) {
    communities(ids: $ids) {
      id
      name
      community_channel
      posts_connection(
        topic_ids: $topics
        limit: $postsLimit
        before: $before
      ) {
        posts {
          id
          community_member {
            id
            avatar_url
            first_name
            last_name
          }
          destroyed_at
          inserted_at
          title
          message {
            id
            message_parts {
              id
              body
              type
              metadata
              sort_order
              link_previews {
                title_link
                service_link
                description
                image_url
              }
            }
            reactions {
              slug
              user_ids
            }
            type
            reply_count
            timestamp
          }
          recent_participants {
            id
            avatar_url
            first_name
            last_name
          }
          message_count
          topic {
            id
            name
          }
          room_id
          message_id
          channel
          is_read
          last_read_at
          last_message {
            timestamp
          }
        }
        page_info {
          start_cursor
          end_cursor
          has_next_page
        }
      }
    }
  }
`;

export const MESSAGES_FOR_CONTAINER_QUERY = gql`
  query MessageForContainer($containerId: [UUID]!) {
    messages_for_container(container_id: $containerId, container_type: "Post") {
      messages {
        id
        user_id
        room_id
        thread_id
        timestamp
        destroyed_at
        reactions {
          slug
          user_ids
        }
        message_parts {
          id
          body
          type
          metadata
          sort_order
          link_previews {
            title_link
            service_link
            description
            image_url
          }
        }
      }
    }
  }
`;

export const JOIN_COMMUNITY = gql`
  mutation JoinCommunity($communityId: UUID!) {
    join_community(communityId: $communityId, input: { join_request: {} })
      @rest(
        type: "JoinCommunity"
        method: "POST"
        path: "/communities/{args.communityId}/join"
      ) {
      id
    }
  }
`;

export const LEAVE_COMMUNITY = gql`
  mutation LeaveCommunity($communityId: UUID!) {
    leave_community(communityId: $communityId, input: {})
      @rest(
        type: "LeaveCommunity"
        method: "POST"
        path: "/communities/{args.communityId}/leave"
      ) {
      id
    }
  }
`;

export const GET_GALLERIES_QUERY = gql`
  query Galleries($ids: [UUID]!) {
    galleries(ids: $ids) {
      id
      gallery_image_uploads {
        id
        image_upload {
          medium_url
        }
      }
    }
  }
`;

export const CREATE_POST = gql`
  mutation create_post($topicId: UUID!, $title: String!, $message: Json!) {
    create_community_post(
      post: { message: $message, title: $title, topic_id: $topicId }
    ) {
      result {
        id
        room_id
        message_id
        inserted_at
        title
        channel
        community_member {
          id
          avatar_url
          first_name
          last_name
        }
        topic {
          id
          name
        }
        recent_participants {
          id
          avatar_url
          first_name
          last_name
        }
        message_count
        message {
          id
          user_id
          room_id
          thread_id
          timestamp
          reactions {
            slug
            user_ids
          }
          message_parts {
            id
            body
            type
            metadata
            sort_order
            link_previews {
              title_link
              service_link
              description
              image_url
            }
          }
        }
      }
    }
  }
`;
