import React, { useEffect, useState } from 'react';
import { push } from '../../store/socket/reducer';
import { useCommentsContext } from '../../store/comments';
import { appendComments, resetComments } from '../../store/comments/actions';
import CommentList from './CommentList';
import { MESSAGE_HISTORY } from '../../services/room_channel_types';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import styles from './details-container.module.scss';
import { useCommunityContext } from '../../store/community';

const CommentsContainer = () => {
  const {
    state: { comments, lastCommentTimestamp },
    dispatch: commentsDispatch,
  } = useCommentsContext();
  const {
    state: { currentActivePost: post, currentActivePostTimestamp },
  } = useCommunityContext();
  const { channel } = post;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    commentsDispatch(resetComments(post));
  }, [commentsDispatch, post]);

  useEffect(() => {
    push({
      channel,
      action: MESSAGE_HISTORY,
      successCallback: ({ messages }) => {
        commentsDispatch(appendComments(messages));
      },
    });
  }, [channel, currentActivePostTimestamp, commentsDispatch]);

  const _loadMore = () => {
    setLoading(true);
    const { channel } = post;
    if (lastCommentTimestamp) {
      push({
        channel,
        action: MESSAGE_HISTORY,
        payload: { before: lastCommentTimestamp },
        successCallback: ({ messages }) => {
          commentsDispatch(appendComments(messages));
          setLoading(false);
        },
      });
    }
  };

  const _renderLoadMoreHistory = () => {
    if (!comments || comments.length === 0) {
      return null;
    }

    const { message_count } = post;
    if (message_count - 1 <= comments.length) {
      return null;
    }

    if (loading) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles['load-older']}>
        <Button wrapperElement={'a'} onClick={_loadMore}>
          Load Older...
        </Button>
      </div>
    );
  };

  return (
    <>
      {_renderLoadMoreHistory()}
      <CommentList post={post} comments={comments} />
    </>
  );
};

export default CommentsContainer;
