import gql from 'graphql-tag';

export const COMMUNITY_BY_USER = gql`
  query communityByUser {
    me {
      id
      communities {
        id
        avatar_url
        name
        slug
        community_color {
          color_value
          custom_color_hexcode
        }
        community_initials
        my_community_member {
          community_color {
            color_value
            custom_color_hexcode
          }
          community_initials
          role
        }
      }
    }
  }
`;
