import React, { useEffect } from 'react';
import { INVITATION_AUTH_QUERY } from '../../graphql/Authentication';
import { useQuery } from 'react-apollo-hooks';
import { useError } from '../../hooks/ErrorHooks';
import Loading from '../../components/Loading';
import {
  INVITATION_BY_TOKEN_QUERY,
  VIEW_INVITATION_QUERY,
} from '../../graphql/Invitation';
import { useGlobalContext } from '../../store/global';
import { updateGlobalContext } from '../../store/global/actions';
import { setInvitationTokens } from '../../helpers/localstorage_helpers';
import { useCommunityContext } from '../../store/community';
import PageNotFound from '../404/PageNotFound';
import HomePage from '../home/HomePage';
import { useInvitationContext } from '../../store/invitation';
import { updateInvitation } from '../../store/invitation/actions';
import { showHud } from '../../store/hud/actions';
import { useHudContext } from '../../store/hud';

export default props => {
  const { dispatch: globalDispatch } = useGlobalContext();
  const { dispatch: invitationDispatch } = useInvitationContext();

  const {
    state: { community },
  } = useCommunityContext();

  const {
    match: {
      params: { space_slug, invitation_token },
    },
  } = props;

  const invitationOptions = {
    variables: {
      communityId: community.id,
      invitationToken: invitation_token,
    },
    skip: !community.id,
  };
  const { error: invitationError, data: invitation } = useQuery(
    INVITATION_BY_TOKEN_QUERY,
    invitationOptions
  );
  useError(invitationError);

  // get auth by user_id and invitation token
  const _getInvitedUser = invitation => {
    if (invitation) {
      const { invitation_by_token } = invitation;
      if (invitation_by_token) {
        const {
          community_invitation: { user },
        } = invitation_by_token;
        return user;
      }
    }
    return null;
  };

  const _getInvitedUserId = invitation => {
    const invitedUser = _getInvitedUser(invitation);
    if (invitedUser) {
      return invitedUser.id;
    }

    return null;
  };

  const invitationAuthOptions = {
    variables: {
      username: _getInvitedUserId(invitation),
      password: invitation_token,
    },
    skip: !_getInvitedUserId(invitation),
  };
  const { error: invitationAuthError, data: invitationAuth } = useQuery(
    INVITATION_AUTH_QUERY,
    invitationAuthOptions
  );
  useError(invitationAuthError);

  useEffect(() => {
    if (invitationAuth) {
      const invitedUser = _getInvitedUser(invitation);
      const {
        invitation_auth: { access_token },
      } = invitationAuth;
      setInvitationTokens({ access_token });

      globalDispatch(
        updateGlobalContext({
          isAuthorized: true,
          isInvitation: true,
          access_token,
          current_login_user: invitedUser,
          user_id: invitedUser.id,
        })
      );

      invitationDispatch(updateInvitation(invitation));
    }
  }, [invitationAuth, invitation, globalDispatch, invitationDispatch]);

  // check if the invitation is valid
  if (invitation) {
    const { invitation_by_token } = invitation;
    if (!invitation_by_token) {
      return <PageNotFound />;
    } else {
      const {
        community_invitation: { join_state },
      } = invitation_by_token;
      if (join_state === 'declined' || join_state === 'joined') {
        window.location.assign(`/spaces/${space_slug}`);
      }
    }
  }

  if (invitationAuth) {
    return (
      <MarkInvitationView>
        <HomePage {...props} />
      </MarkInvitationView>
    );
  }

  return <Loading />;
};

/**
 * Use to mark the invitation as viewed before enter the home page.
 *
 * @param children
 * @returns {*}
 * @constructor
 */
const MarkInvitationView = ({ children }) => {
  const {
    state: {
      invitation: { invitation_by_token },
    },
  } = useInvitationContext();
  const { dispatch: hudDispatch } = useHudContext();

  const _getCommunityId = () => {
    if (invitation_by_token) {
      const {
        community_invitation: { community_id },
      } = invitation_by_token;
      return community_id;
    }
    return null;
  };

  const _getInvitationId = () => {
    if (invitation_by_token) {
      const {
        community_invitation: { id },
      } = invitation_by_token;
      return id;
    }

    return null;
  };

  const _getLastUpdatedAt = () => {
    if (invitation_by_token) {
      const {
        community_invitation: { updated_at },
      } = invitation_by_token;
      return updated_at;
    }

    return null;
  };

  const _shouldMarkAsViewed = () => {
    if (invitation_by_token) {
      const {
        community_invitation: { delivery_state },
      } = invitation_by_token;
      // only mark the delivered state invitation, other state will be ignored.
      return delivery_state === 'delivered';
    }

    return false;
  };

  const markInvitationOptions = {
    variables: {
      communityId: _getCommunityId(),
      invitationId: _getInvitationId(),
      lastUpdatedAt: _getLastUpdatedAt(),
    },
    skip: !_shouldMarkAsViewed(),
  };
  const { data, error } = useQuery(
    VIEW_INVITATION_QUERY,
    markInvitationOptions
  );

  useEffect(() => {
    if (error) {
      const {
        networkError: { statusCode, message },
      } = error;

      if (
        (statusCode && statusCode !== 422) ||
        (message && message.indexOf('view_invitation_update') === -1)
      ) {
        hudDispatch(showHud());
      }
    }
  }, [data, error, hudDispatch]);

  return children;
};
