import PropTypes from 'prop-types';
import React from 'react';
import SimpleReactModal from 'simple-react-modal';
import classNames from 'classnames';

import styles from './hobnob-modal.module.scss';
import CancelIcon from '../assets/icon/cancel.svg';

export default function HobnobModal(props) {
  const {
    containerClassName,
    show,
    style,
    onClose,
    title,
    subTitle,
    contentClassName,
    cancelClassName,
    cancelWrapperClassName,
    showCancelIcon,
    children,
    leftContent,
    closeOnOuterClick,
  } = props;
  const defaultContainerStyles = {
    padding: '10px 10px 30px',
    borderRadius: '10px',
    margin: '10vh auto 5px',
    width: '90vw',
    maxWidth: '500px',
    minHeight: '470px',
    backgroundColor: '#F9F9F9',
  };

  const modalStyle = {
    transition: 'opacity .5s ease-in-out',
    background: 'rgba(48, 48, 48, 0.85)',
    fontFamily: null,
    ...style,
  };

  const containerStyles = {
    ...defaultContainerStyles,
    ...props.containerStyles,
  };

  const _onClose = e => {
    e.preventDefault();
    onClose(e);
  };

  return (
    <SimpleReactModal
      show={show}
      onClose={onClose}
      style={modalStyle}
      containerClassName={containerClassName}
      containerStyle={containerStyles}
      transitionSpeed={250}
      closeOnOuterClick={closeOnOuterClick}
    >
      {leftContent || showCancelIcon ? (
        <div
          className={classNames(
            `${styles['cancel-button-container']} d-flex ${
              leftContent ? 'justify-content-between' : 'justify-content-end'
            } ${cancelWrapperClassName}`
          )}
        >
          {leftContent}

          {showCancelIcon && (
            <img
              className={classNames(styles['cancel-icon'], cancelClassName)}
              alt={'cancel'}
              src={CancelIcon}
              onClick={_onClose}
            />
          )}
        </div>
      ) : null}

      <div className={classNames(styles['content'], contentClassName)}>
        {title ? <h3 className={styles['title']}>{title}</h3> : null}
        {subTitle ? <div>{subTitle}</div> : null}
        {children}
      </div>
    </SimpleReactModal>
  );
}

HobnobModal.propTypes = {
  containerClassName: PropTypes.string,
  containerStyles: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  style: PropTypes.object,
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  contentClassName: PropTypes.string,
  cancelClassName: PropTypes.string,
  cancelWrapperClassName: PropTypes.string,
  showCancelIcon: PropTypes.bool,
  leftContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  closeOnOuterClick: PropTypes.bool,
};
HobnobModal.defaultProps = {
  cancelButtonInContainer: false,
  showCancelIcon: true,
  closeOnOuterClick: true,
};
