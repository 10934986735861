import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo-hooks';

import styles from './switcher.module.scss';
import createSpaceIcon from '../../assets/icon/create-space-icon.svg';

import { useWindowSize } from '../../hooks/WindowHooks';

import ReactTooltip from 'react-tooltip';

import { COMMUNITY_BY_USER } from '../../graphql/User';
import { useError } from '../../hooks/ErrorHooks';

import { useCommunityContext } from '../../store/community';
import { sortCommunities } from '../../helpers/community_helpers';
import { BANNED_USER, PREVIOUS_MEMBER } from '../../consts/MemberRoles';
import { useGlobalContext } from '../../store/global';
import SpaceAvatar from '../SpaceAvatar';

const _filterActiveCommunities = communities => {
  const activeCommunities = communities.filter(({ my_community_member }) => {
    if (!my_community_member) {
      return false;
    }

    const { role } = my_community_member;
    // remove communities which my role is banned or previous_member(removed)
    if (role === BANNED_USER || role === PREVIOUS_MEMBER) {
      return false;
    }
    return true;
  });

  return {
    activeCommunities: activeCommunities,
    activeCommunitiesCount: activeCommunities.length,
  };
};

function Switcher() {
  const { state: globalContext } = useGlobalContext();
  const {
    state: { community },
  } = useCommunityContext();
  const { id: communityId } = community;
  const { height: innerHeight } = useWindowSize();

  const [overflow, setOverflow] = useState(false);

  const { loading, error, data } = useQuery(COMMUNITY_BY_USER, {
    skip: globalContext.isInvitation,
  });
  useError(error);

  useEffect(() => {
    const _communityContainerStyleUpdate = communities_count => {
      // padding-top and padding-bottom
      const sideBarPaddingHeight = 12 * 2;
      const communityIconHeight = 50;

      const clientHeight = innerHeight - sideBarPaddingHeight;
      const maxCommunityCount = Math.ceil(clientHeight / communityIconHeight);
      if (maxCommunityCount + 1 < communities_count) {
        // actual community count is over the max size, allow the user to scroll
        setOverflow(true);
      }
    };

    if (data && data.me) {
      const {
        me: { communities },
      } = data;
      const { activeCommunitiesCount } = _filterActiveCommunities(communities);
      _communityContainerStyleUpdate(activeCommunitiesCount);
    }
  }, [innerHeight, data, error]);

  if (loading) {
    return null;
  }

  // display the current community as default.
  let displayCommunities = [community];
  if (!globalContext.isInvitation && data.me) {
    const {
      me: { communities },
    } = data;
    const { activeCommunities } = _filterActiveCommunities(communities);
    displayCommunities = sortCommunities(activeCommunities, communityId);
  }

  return (
    <div className={styles['wrapper']}>
      <div
        className={styles['avatar-wrapper']}
        style={{ flex: overflow ? '1' : '' }}
      >
        {displayCommunities.map(item => (
          <div key={item.id} className={'pb-3'}>
            <SpaceAvatar space={item} communityId={communityId} />
          </div>
        ))}
      </div>

      <img
        data-tip={'Download the app to start a new Space'}
        data-for={'download'}
        src={createSpaceIcon}
        alt="Create Space"
      />
      <ReactTooltip
        id={'download'}
        effect={'solid'}
        className={styles['tooltip']}
      />
    </div>
  );
}

export default Switcher;
