import React from 'react';
import PropTypes from 'prop-types';

import { GET_GALLERIES_QUERY } from '../../graphql/Community';
import { useQuery } from 'react-apollo-hooks';
import SpaceCarousel from '../SpaceCarousel';
import LoadingIndicator from '../LoadingIndicator';

export default function Gallery({ metadata }) {
  const { gallery_id } = metadata;

  const options = {
    variables: {
      ids: gallery_id,
    },
    skip: !gallery_id,
  };
  const { loading, data } = useQuery(GET_GALLERIES_QUERY, options);

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  const { galleries } = data;
  if (!galleries) {
    return null;
  }

  const { gallery_image_uploads: galleryImageUploads } = galleries[0];
  if (!galleryImageUploads.length) {
    return null;
  }

  return (
    <div className="mt-3">
      <SpaceCarousel dataSource={galleryImageUploads} />
    </div>
  );
}
Gallery.propTypes = {
  metadata: PropTypes.object,
};
