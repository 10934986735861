import React, { useEffect } from 'react';
import { useGlobalContext } from '../store/global';
import { useCommunityContext } from '../store/community';
import { useQuery } from 'react-apollo-hooks';
import { COMMUNITY_BY_SLUG_QUERY } from '../graphql/Community';
import { requestCommunity } from '../store/community/actions';
import { useError } from '../hooks/ErrorHooks';
import Loading from '../components/Loading';
import AnonymousModalContainer from '../components/modals/AnonymousModalContainer';
import ErrorModal from '../components/modals/ErrorModal';
import PageNotFound from './404/PageNotFound';

export const GlobalPage = props => {
  const { children, match } = props;
  const { state: globalState } = useGlobalContext();
  const { dispatch: communityDispatch } = useCommunityContext();

  const {
    params: { space_slug, invitation_token },
  } = match;
  const queryCommunityBySlugOption = {
    variables: {
      slug: space_slug,
    },
  };
  const { loading, error, data } = useQuery(
    COMMUNITY_BY_SLUG_QUERY,
    queryCommunityBySlugOption
  );
  useError(error);

  useEffect(() => {
    if (data) {
      const { community_by_slug } = data;
      if (community_by_slug) {
        communityDispatch(requestCommunity(community_by_slug));
        window.document.title = `${community_by_slug.name} | Hobnob`;
      }
    }
  }, [data, communityDispatch]);

  if (loading || !data) {
    return <Loading />;
  }

  // switch the url to non-invitation URL
  if (globalState.switch_to_normal_mode && invitation_token) {
    window.location.href = `/spaces/${space_slug}`;
  }

  if (!globalState.isAuthorized && !globalState.isInvitation) {
    return <AnonymousModalContainer />;
  }

  if (globalState.isError || globalState.isServiceUnavailable) {
    return <ErrorModal show={true} />;
  }

  const { community_by_slug } = data;
  if (!community_by_slug) {
    return <PageNotFound />;
  }

  return children;
};
