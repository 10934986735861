import { UPDATE_INVITATION } from './actionType';

export const invitationInit = {
  invitation: {},
};

const reducer = (state = invitationInit, action) => {
  const { type } = action;
  switch (type) {
    case UPDATE_INVITATION:
      return { ...state, invitation: action.params };
    default:
      return state;
  }
};

export default reducer;
