import { ACCESS_TOKEN } from '../../apollo-links/AuthLink';
import { Socket } from 'phoenix';

let socket = null;
const channels = {};
const subscriptions = [];

export function createSocket() {
  if (socket) return;

  const access_token = localStorage.getItem(ACCESS_TOKEN);
  socket = new Socket(process.env.REACT_APP_WEB_SOCKET_URL, {
    params: {
      token: access_token,
      client: process.env.REACT_APP_HOBNOB_CLIENT_VERSION,
    },
  });

  socket.onError(e =>
    console.error('there was an error with the connection', e)
  );
  socket.onOpen(() => console.log('websocket connected'));
  socket.connect();
}

if (localStorage.getItem(ACCESS_TOKEN)) {
  createSocket();
}

export function join(channelName) {
  if (channels[channelName]) {
    return channels[channelName];
  } else {
    const newChannel = new Promise((resolve, reject) => {
      const channel = socket.channel(channelName);
      channel
        .join()
        .receive('ok', () => {
          console.log(`joind channel ${channelName} successfully`);
          resolve(channel);
        })
        .receive('error', error => {
          console.error(`joined channel ${channelName} is failed. `, error);
          reject(error);
        });
    });
    channels[channelName] = newChannel;
    return newChannel;
  }
}

export function push(params) {
  const {
    channel: channelName,
    action,
    payload,
    successCallback,
    errorCallback,
  } = params;

  join(channelName)
    .then(channel =>
      channel
        .push(action, payload || {}, 100000)
        .receive('ok', res => {
          if (successCallback) {
            successCallback(res);
          }
        })
        .receive('error', error => {
          console.error(`push ${action} to ${channelName} failed: `, error);
          if (errorCallback) {
            errorCallback(error);
          }
        })
        .receive('timeout', () => console.error('timed out pushing'))
    )
    .catch(err => {
      console.error(`push ${action} to ${channelName} failed: `, err);
    });
}

const _canSubscribe = (subscriptions, channelName, action) => {
  return subscriptions.indexOf(channelName + action) === -1;
};

export function subscribe(params) {
  const { channel: channelName, subscribers = [] } = params;

  join(channelName).then(channel => {
    subscribers.forEach(({ action, onReceived }) => {
      if (_canSubscribe(subscriptions, channelName, action)) {
        console.log('subscribe: ', channelName, ' action: ', action);
        channel.on(action, onReceived);
        subscriptions.push(channelName + action);
      }
    });
  });
}

// not used
const reducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export default reducer;
