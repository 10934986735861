/**
 * RESTFul request by using apollo-link-rest.
 * Reference: https://www.apollographql.com/docs/link/links/rest/
 */
import gql from 'graphql-tag';

export const CLIENT_CREDENTIAL_QUERY = gql`
  query ClientAuth($client_id: String!, $client_secret: String!) {
    client_auth(
      input: {
        client_id: $client_id
        client_secret: $client_secret
        grant_type: "client_credentials"
      }
    ) @rest(type: "ClientAuth", method: "POST", path: "/oauth/token") {
      access_token
      created_at
      expires_in
      token_type
    }
  }
`;

export const USER_AUTH_QUERY = gql`
  query UserAuth(
    $username: String!
    $password: String
    $firstName: String
    $lastName: String
  ) {
    user_auth(
      input: {
        username: $username
        password: $password
        first_name: $firstName
        last_name: $lastName
        grant_type: "password"
        contact_method_type: "PhoneNumberContactMethod"
      }
    ) @rest(type: "UserAuth", method: "POST", path: "/oauth/token") {
      access_token
      created_at
      expires_in
      token_type
      refresh_token
    }
  }
`;

export const CURRENT_LOGIN_USER_QUERY = gql`
  query CurrentLoginUser {
    current_login_user
      @rest(type: "CurrentLoginUser", method: "GET", path: "/user") {
      user @type(name: "LoginUser") {
        id
        first_name
        last_name
        verified
        avatar @type(name: "LoginUserAvatar") {
          small_url_2x
          medium_url_2x
        }
      }
    }
  }
`;

export const INVITATION_AUTH_QUERY = gql`
  query InvitationAuth($username: String!, $password: String!) {
    invitation_auth(
      input: {
        username: $username
        password: $password
        grant_type: "password"
        authentication_method: "CommunityInvitation"
        scope: "community_invitation_credential"
      }
    ) @rest(type: "InvitationAuth", method: "POST", path: "/oauth/token") {
      access_token
      created_at
      expires_in
      token_type
      scope
    }
  }
`;
