import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './description.module.scss';
import Button from '../Button';

import { useWindowSize } from '../../hooks/WindowHooks';

/**
 * Get the value of the css style property
 */
function getStyle(obj, attr) {
  if (obj.currentStyle) {
    return obj.currentStyle[attr];
  } else {
    return document.defaultView.getComputedStyle(obj, null)[attr];
  }
}

function Description({ description }) {
  const [showEllipsis, setShowEllipsis] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const ellipsisRef = useRef(null);
  const innerWidth = useWindowSize().width;

  useEffect(() => {
    const currentDom = ellipsisRef.current;
    const clientHeight = currentDom.clientHeight;

    let lineHeight = getStyle(currentDom, 'lineHeight');
    lineHeight = parseInt(lineHeight.replace('px', ''));

    let lineNumber = Math.ceil(clientHeight / lineHeight);

    if (lineNumber > 2) {
      setShowEllipsis(true);
      setShowReadMore(true);
    } else if (description.length >= 88) {
      // when slide menu display none,can't get the current dom
      setShowEllipsis(true);
      setShowReadMore(true);
    } else {
      setShowEllipsis(false);
      setShowReadMore(false);
    }
  }, [ellipsisRef, description.length, innerWidth]);

  const _renderReadMore = () => {
    if (!showEllipsis) {
      return null;
    } else if (showReadMore) {
      return (
        <Button
          type="text"
          className="text-decoration-none border-0"
          onClick={() => setShowReadMore(!showReadMore)}
        >
          Read more
        </Button>
      );
    } else {
      return (
        <Button
          type="text"
          className="text-decoration-none"
          onClick={() => setShowReadMore(!showReadMore)}
        >
          Hide
        </Button>
      );
    }
  };

  return (
    <div className={styles['description-wrapper']}>
      <div
        className={styles['space-description']}
        ref={ellipsisRef}
        style={{ WebkitLineClamp: showEllipsis && showReadMore ? 2 : null }}
      >
        {description}
      </div>

      {_renderReadMore()}
    </div>
  );
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;
