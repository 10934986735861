import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import AvatarBorder from './AvatarBorder';

import styles from './avatar.module.scss';

import GenericAvatar from '../assets/icon/avatar_generic@2x.png';

export default function Avatar(props) {
  const {
    url,
    user,
    size,
    includeBorder,
    onClick,
    style,
    className,
    textOverride,
  } = props;

  const { avatar_url, first_name, last_name } = user;
  const isMasked = !first_name && !last_name && !textOverride;
  const [showInitial, setShowInitial] = useState(false);

  const _fontSize = () => {
    if (size >= 80) {
      return '33px';
    } else if (size >= 60) {
      return '24px';
    } else if (size >= 30) {
      return '15px';
    } else if (size > 20) {
      return '12px';
    } else {
      return '10px';
    }
  };

  const _borderSize = () => {
    if (size >= 80) {
      return 3;
    } else if (size >= 60) {
      return 2;
    } else {
      return 1;
    }
  };

  const _styles = () => {
    const sizeInPx = `${size}px`;

    const sharedStyles = {
      borderRadius: sizeInPx,
      height: sizeInPx,
      minWidth: sizeInPx,
      width: sizeInPx,
    };

    if (onClick) sharedStyles.cursor = 'pointer';

    if ((avatar_url || isMasked) && !showInitial) {
      return {
        ...sharedStyles,
        ...style,
      };
    } else {
      return {
        ...sharedStyles,
        backgroundColor: '#C2C4C7',
        lineHeight: sizeInPx,
        fontSize: _fontSize(),
        ...style,
      };
    }
  };

  const _renderInitials = () => {
    if (textOverride) return textOverride;

    let firstInitial = first_name ? first_name[0] : '';

    let lastInitial = last_name ? last_name[0] : '';

    if (firstInitial.charCodeAt(0) > 255) {
      firstInitial += first_name[1];
    }

    if (lastInitial.charCodeAt(0) > 255) {
      lastInitial += last_name[1];
    }

    return (firstInitial + lastInitial).toUpperCase();
  };

  const _onImageError = e => {
    e.target.src = null;
    e.target.onerror = null;
    setShowInitial(true);
  };

  const _renderAvatar = () => {
    const avatarUrl = url || avatar_url;
    if (avatarUrl && !showInitial) {
      return (
        <img
          className={ClassNames(className, styles['avatar'])}
          src={avatarUrl}
          alt={'avatar-url'}
          style={_styles()}
          onError={_onImageError}
          onClick={onClick}
        />
      );
    } else if (isMasked && !showInitial) {
      return (
        <img
          className={ClassNames(className, styles['avatar'])}
          alt={'GenericAvatar'}
          src={GenericAvatar}
          style={_styles()}
          onError={_onImageError}
          onClick={onClick}
        />
      );
    } else {
      return (
        <div
          style={_styles()}
          className={ClassNames(className, styles['fauxAvatar'])}
          onClick={onClick}
        >
          {_renderInitials(props)}
        </div>
      );
    }
  };

  return (
    <AvatarBorder
      borderWidth={_borderSize()}
      includeBorder={includeBorder}
      size={size}
      appUser={true}
    >
      {_renderAvatar()}
    </AvatarBorder>
  );
}

Avatar.defaultProps = {
  size: 30,
  includeBorder: false,
};

Avatar.propTypes = {
  url: PropTypes.string,
  user: PropTypes.object,
  size: PropTypes.number,
  includeBorder: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.string,
  className: PropTypes.string,
  textOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
