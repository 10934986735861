import PropTypes from 'prop-types';
import React from 'react';

import { mapUrl, staticMapImageUrl } from '../../helpers/map_helpers';

import styles from './location-body.module.scss';
import Button from '../Button';

export default function LocationBody({ comment, className }) {
  const { latitude, longitude } = comment.metadata;
  const mapImageUrl = staticMapImageUrl(latitude, longitude, 16);

  return (
    <div className={className}>
      <Button
        wrapperElement="a"
        target="_blank"
        href={mapUrl(latitude, longitude)}
      >
        <img
          src={mapImageUrl}
          className={styles['static-map']}
          alt={'location'}
        />
      </Button>
    </div>
  );
}

LocationBody.propTypes = {
  comment: PropTypes.object.isRequired,
  className: PropTypes.string,
};
