import {
  UPDATE_GLOBAL_CONTEXT,
  LOGOUT,
  HIDE_FLOATING_BUTTON,
} from './actionType';

import {
  USER_ACCESS_TOKEN,
  USER_REFRESH_TOKEN,
} from '../../apollo-links/AuthLink';
import { clearTokens } from '../../helpers/localstorage_helpers';

const accessToken = localStorage.getItem(USER_ACCESS_TOKEN);
const refreshToken = localStorage.getItem(USER_REFRESH_TOKEN);

export const globalContextInit = {
  accessToken: accessToken,
  refreshToken: refreshToken,
  isAuthorized: true,
  isError: false,
  isServiceUnavailable: false,
  errorMessage: '',
  user_id: '',
  current_login_user: {},
  isInvitation: false,
  switch_to_normal_mode: false,
  floatingButtonVisible: true,
};

const reducer = (state = globalContextInit, action) => {
  const { type } = action;
  switch (type) {
    case UPDATE_GLOBAL_CONTEXT:
      return { ...state, ...action.params };
    case LOGOUT:
      clearTokens();
      window.location.reload();
      return state;
    case HIDE_FLOATING_BUTTON:
      return { ...state, floatingButtonVisible: false };
    default:
      return globalContextInit;
  }
};

export default reducer;
