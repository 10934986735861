import gql from 'graphql-tag';

export const COMMUNITY_INVITATIONS_QUERY = gql`
  query CommunityInvitationsQuery($communityId: UUID!) {
    community_invitations_query(communityId: $communityId)
      @rest(
        type: "CommunityInvitationsQuery"
        method: "GET"
        path: "/communities/{args.communityId}/invitations"
      ) {
      community_invitations @type(name: "CommunityInvitations") {
        id
        community_id
        join_state
        created_at
        created_by_user @type(name: "CreatedByUser") {
          id
          first_name
          last_name
        }
        user @type(name: "InvitedUser") {
          id
          first_name
          last_name
          avatar @type(name: "InvitedUserAvatar") {
            small_url_2x
          }
        }
      }
    }
  }
`;
