import _ from 'lodash';

export const sortCommunities = communities => {
  if (!communities || communities.length === 0) {
    return [];
  }

  return _.sortBy(communities, [community => community.name]);
};

export const sortTopics = (firstTopic, secondTopic) => {
  const nameA = firstTopic.name.toUpperCase();
  const nameB = secondTopic.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
};

export const filterDestroyedPosts = (posts = []) => {
  return posts.filter(post => !post.destroyed_at);
};

// process space icon background
const PREDEFINED_COLORS = {
  electric_violet: '#9013FE',
  science_blue: '#006ECA',
  bright_turquoise: '#0FD7F7',
  candlelight: '#FFE11B',
  sunshade: '#FFA428',
};

const _getBackgroundColor = ({ color_value, custom_color_hexcode }) => {
  let backgroundColor = `#${custom_color_hexcode}`;
  if (color_value !== 'custom' && !custom_color_hexcode) {
    backgroundColor = PREDEFINED_COLORS[color_value];
  }

  return backgroundColor;
};

export const getSpaceIconBackground = space => {
  const { community_color, my_community_member } = space;

  // override the default if my community member has color settings
  if (my_community_member) {
    const { community_color: my_community_color } = my_community_member;

    if (my_community_color) {
      return _getBackgroundColor(my_community_color);
    }
  }

  return _getBackgroundColor(community_color);
};
