import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import styles from './divider.module.scss';

function Divider(props) {
  const { children, className, innerTextClassName } = props;
  return (
    <div className={ClassNames(styles['divider'], className)}>
      <span className={ClassNames(styles['inner-text'], innerTextClassName)}>
        {children}
      </span>
    </div>
  );
}

Divider.propType = {
  className: PropTypes.string,
  innerTextClassName: PropTypes.string,
};
export default Divider;
