import React, { useEffect } from 'react';
import Comment from './Comment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './details-container.module.scss';
import Divider from '../Divider';
import {
  formatUnixDate,
  formatUnixTimestamp,
} from '../../helpers/date_helpers';
import LoadingIndicator from '../LoadingIndicator';
import { push } from '../../store/socket/reducer';
import { MARK_MESSAGE_READ } from '../../services/room_channel_types';
import { useCommunityContext } from '../../store/community';
import { setLastReadAt } from '../../store/community/actions';

function createHierarchyComments(messages) {
  const groupedMessages = {};
  const parentMessages = messages.filter(message => {
    const { id, thread_id } = message;
    return thread_id === null || thread_id === id;
  });

  parentMessages.forEach(parent_message => {
    const { id } = parent_message;
    const subThreads = messages.filter(message => {
      const { id: message_id, thread_id } = message;
      return thread_id === id && thread_id !== message_id;
    });
    groupedMessages[id] = { ...parent_message, sub_threads: subThreads };
  });

  return Object.keys(groupedMessages).map(key => groupedMessages[key]);
}

function groupMessagesByDate(comments) {
  const orderedComments = _.sortBy(comments, [comment => comment.timestamp]);
  const hierarchyComments = createHierarchyComments(orderedComments);
  const commentsGroupByDate = _.groupBy(hierarchyComments, comment =>
    formatUnixDate(comment.timestamp)
  );

  return Object.keys(commentsGroupByDate).map(date => {
    const comments = commentsGroupByDate[date];
    const maxDateMessage = _.minBy(comments, message => message.timestamp);
    return {
      date: formatUnixTimestamp(maxDateMessage.timestamp),
      messages: comments,
    };
  });
}

function CommentList({ post, comments }) {
  const { dispatch: communityDispatch } = useCommunityContext();

  const isInViewport = elem => {
    if (elem) {
      const bounding = elem.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    return false;
  };

  useEffect(() => {
    const {
      channel,
      message: { timestamp },
      last_read_at,
    } = post;

    let lastReadAt = last_read_at;
    // mark the post as read by using the last message timestamp.
    // the last message should be in the viewport.
    const markPostAsRead = lastCommentInViewport => {
      // use the post timestamp as default
      let lastTimestamp = timestamp;
      if (lastCommentInViewport) {
        // if it has comments, use the last comment timestamp which in the viewport
        lastTimestamp = lastCommentInViewport.timestamp;
      }

      // only the comments timestamp is greater than the last read at will push the mark as read
      if (lastReadAt < lastTimestamp) {
        lastReadAt = lastTimestamp;
        push({
          channel,
          action: MARK_MESSAGE_READ,
          payload: { timestamp: lastTimestamp },
          successCallback: () =>
            communityDispatch(setLastReadAt(lastTimestamp)),
        });
      }
    };

    // check the comments are in the viewport or not.
    let checkTimeout;
    const check = () => {
      // add the timeout to avoid the frequently check during the scroll event.
      // refer to: https://gomakethings.com/detecting-when-a-visitor-has-stopped-scrolling-with-vanilla-javascript/
      window.clearTimeout(checkTimeout);
      checkTimeout = setTimeout(() => {
        const commentsInViewport = comments
          .map(comment => {
            if (isInViewport(document.getElementById(comment.id))) {
              return comment;
            }
            return null;
          })
          .filter(comment => comment != null);

        // get the max timestamp of the comment in the viewport
        const lastCommentInViewport = _.maxBy(commentsInViewport, 'timestamp');
        markPostAsRead(lastCommentInViewport);
      }, 1000);
    };

    if (comments && comments.length > 0) {
      // default check
      check();

      // add the scroll check
      const detailContainer = document.getElementById('post-detail');
      detailContainer.addEventListener('scroll', check);
      return () => detailContainer.removeEventListener('scroll', check);
    }
  }, [post, comments, communityDispatch]);

  if (!comments || comments.length === 0) {
    return <LoadingIndicator />;
  }

  const postMessage = post.message;

  let commentList = comments
    .filter(item => !item.destroyed_at)
    .filter(item => item.id !== postMessage.id);
  const commentsGroupedByDate = groupMessagesByDate(commentList);

  const _renderComments = messages => {
    return messages.map(item => {
      return <Comment key={item.id} comment={item} />;
    });
  };

  return commentsGroupedByDate.map(commentsByDate => {
    const { date, messages } = commentsByDate;
    return (
      <span key={date}>
        <Divider innerTextClassName={styles['inner-text']}>{date}</Divider>
        <div className="p-3">{_renderComments(messages)}</div>
      </span>
    );
  });
}

CommentList.propTypes = {
  post: PropTypes.object,
  comments: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default CommentList;
