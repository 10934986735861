import React from 'react';
import { CLIENT_ACCESS_TOKEN } from '../apollo-links/AuthLink';
import { CLIENT_CREDENTIAL_QUERY } from '../graphql/Authentication';
import ErrorPage from '../pages/error/ErrorPage';
import { useQuery } from 'react-apollo-hooks';
import Loading from './Loading';

export default function ClientCredentialLoader({ children }) {
  const queryOptions = {
    variables: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    },
  };
  const { data, loading, error } = useQuery(
    CLIENT_CREDENTIAL_QUERY,
    queryOptions
  );

  if (loading || error) return <Loading />;

  const { client_auth } = data;
  if (!client_auth) return <ErrorPage />;

  // set the client credential access token into localStorage
  const { access_token } = client_auth;
  localStorage.setItem(CLIENT_ACCESS_TOKEN, access_token);

  return children;
}
