import React from 'react';
import PropTypes from 'prop-types';
import styles from './mention-member.module.scss';
import _ from 'lodash';
import LinkPreview from './LinkPreview';

export default function TextMessage(props) {
  const { body, linkPreviews } = props;

  let unescapedBody = _.unescape(body);

  // link regular expression
  const linkReg = /(<link:https?:\/\/[\w-]+\.[\w-]+[\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#]+>)|(<link:ftps?:\/\/[\w-]+\.[\w-]+[\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#]+>)/g;
  const urlReg = /((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])/;

  // mention user regular expression
  const mentionedUserReg = /(<implicit_group:[^<>|]+\|@[^>]+>)|(<user:[^<>|]+\|@[^>]+>)/g;
  const usernameReg = /(@[^>]+)/g;

  const _processMentions = (part, index) => {
    let sub = part.match(usernameReg);
    if (sub) {
      return (
        <span key={index}>
          <strong>{sub[0]}</strong>
        </span>
      );
    }
    return part;
  };

  const _processLink = part => {
    const linkResult = part.split(linkReg).map((linkPart, index) => {
      if (!linkPart) {
        return linkPart;
      }

      let sub = linkPart.match(urlReg);
      if (sub) {
        return (
          <a href={sub[0]} key={index} target={'_blank'}>
            {sub[0]}
          </a>
        );
      }
      return linkPart;
    });

    return linkResult;
  };

  const results = unescapedBody.split(mentionedUserReg).map((part, index) => {
    if (!part) {
      return part;
    } else {
      if (part.match(mentionedUserReg)) {
        return _processMentions(part, index);
      }
      return _processLink(part);
    }
  });

  return (
    <React.Fragment>
      <div className={styles['text']}>{results}</div>
      {/* display link preview if it has */}
      {linkPreviews &&
        linkPreviews.map((linkPreview, index) => (
          <LinkPreview key={index} linkPreview={linkPreview} />
        ))}
    </React.Fragment>
  );
}

TextMessage.defaultProps = {
  hover: true,
};

TextMessage.propTypes = {
  body: PropTypes.string.isRequired,
  linkPreviews: PropTypes.array,
};
