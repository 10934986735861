import React from 'react';
import Proptypes from 'prop-types';
import ClassNames from 'classnames';
import styles from './space-cover-image.module.scss';

function SpaceCoverImage({ cover_image_url }) {
  return (
    <div
      className={ClassNames(
        `d-flex align-items-center justify-content-center ${
          styles['space-cover-image']
        }`
      )}
      style={{ backgroundImage: `url(${cover_image_url})` }}
    />
  );
}

SpaceCoverImage.propTypes = {
  cover_image_url: Proptypes.string,
};

export default SpaceCoverImage;
