import {
  USER_ACCESS_TOKEN,
  USER_REFRESH_TOKEN,
  INVITATION_ACCESS_TOKEN,
  ACCESS_TOKEN,
} from '../apollo-links/AuthLink';

export const setLoginTokens = ({ access_token, refresh_token }) => {
  if (access_token) {
    localStorage.setItem(USER_ACCESS_TOKEN, access_token);
    localStorage.setItem(ACCESS_TOKEN, access_token);
  }

  if (refresh_token) {
    localStorage.setItem(USER_REFRESH_TOKEN, refresh_token);
  }
};

export const useLoginAccessToken = () => {
  const user_access_token = localStorage.getItem(USER_ACCESS_TOKEN);
  localStorage.setItem(ACCESS_TOKEN, user_access_token);
};

export const setInvitationTokens = ({ access_token }) => {
  if (access_token) {
    localStorage.setItem(INVITATION_ACCESS_TOKEN, access_token);
    localStorage.setItem(ACCESS_TOKEN, access_token);
  }
};

export const clearTokens = () => {
  localStorage.removeItem(USER_ACCESS_TOKEN);
  localStorage.removeItem(USER_REFRESH_TOKEN);
};
