import React, { useState } from 'react';
import PropTypes from 'prop-types';

import HobnobModal from '../HobnobModal';
import UserNameForm from './UserNameForm';
import ConfirmationForm from './ConfirmationForm';
import LoginConfirmationMessage from './LoginConfirmationMessage';

import styles from './join-modal.module.scss';

import LeftArrow from '../../assets/icon/left_arrow.svg';

import { useMutation } from 'react-apollo-hooks';
import { USER_AUTH_QUERY } from '../../graphql/Authentication';

const defaultFields = {
  firstName: '',
  lastName: '',
  countryCode: 'US',
  phoneNumber: '',
  formattedPhoneNumber: '',
  confirmationCode: '',
};

export default function UserLoginForm(props) {
  const {
    children,
    show,
    onClose,
    contactMethod,
    onLoginSuccess,
    customizedMessage,
    modal,
  } = props;

  const [showUserNameForm, setShowUserName] = useState(true);
  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const [isErrorCode, setIsErrorCode] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [login, { loading }] = useMutation(USER_AUTH_QUERY);

  const processError = error => {
    const { networkError } = error;
    if (networkError) {
      const { statusCode, result } = networkError;
      if (statusCode === 401) {
        if (result.error === 'otp_required') {
          setShowUserName(false);
          setShowConfirmationForm(true);
          setIsErrorCode(false);
        } else {
          setIsErrorCode(true);
        }
      } else {
        setIsErrorCode(true);
      }
    }
  };

  const processData = ({ data }) => {
    const { user_auth } = data;
    if (!user_auth) {
      setIsErrorCode(true);
    } else {
      onLoginSuccess(user_auth);
    }
  };

  const _login = variables => {
    login({ variables }).then(
      res => processData(res),
      error => processError(error)
    );
  };

  function _onPrevious() {
    setShowUserName(!showUserNameForm);
    setShowConfirmationForm(!showConfirmationForm);
    setIsErrorCode(false);
  }

  function _onConfirm() {
    const variables = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      password: fields.confirmationCode,
      username: fields.phoneNumber,
    };

    _login(variables);
  }

  function _updateForm(params) {
    setFields({
      ...fields,
      ...params,
    });
    setIsErrorCode(false);
  }

  function _submitForm() {
    const variables = {
      username: fields.phoneNumber,
    };
    _login(variables);
  }

  function _onResendCode() {
    _submitForm();
  }

  function _renderComponent() {
    if (showUserNameForm) {
      return (
        <div className={styles['form-wrapper']}>
          {customizedMessage}
          <UserNameForm
            fields={fields}
            updateForm={_updateForm}
            submitForm={_submitForm}
            loading={loading}
            isError={isErrorCode}
          />
        </div>
      );
    } else if (showConfirmationForm) {
      return (
        <div className={styles['form-wrapper']}>
          <LoginConfirmationMessage
            loginForm={fields}
            contactMethod={contactMethod}
          />
          <ConfirmationForm
            fields={fields}
            onConfirm={_onConfirm}
            updateForm={_updateForm}
            onResendCode={_onResendCode}
            loading={loading}
            isError={isErrorCode}
          />
        </div>
      );
    }

    return null;
  }

  function _renderLeftContent(className) {
    if (showConfirmationForm) {
      return (
        <img
          src={LeftArrow}
          alt={'left'}
          className={className}
          onClick={() => _onPrevious()}
        />
      );
    }

    return null;
  }

  if (modal) {
    return (
      <HobnobModal
        show={show}
        onClose={onClose}
        contentClassName={styles['wrapper']}
        leftContent={_renderLeftContent(styles['left-arrow-icon'])}
        closeOnOuterClick={false}
      >
        {children}
        {_renderComponent()}
      </HobnobModal>
    );
  } else {
    return (
      <div>
        {_renderLeftContent(styles['left-arrow-icon-modal'])}
        {children}
        {_renderComponent()}
      </div>
    );
  }
}

UserLoginForm.defaultProps = {
  modal: true,
};

UserLoginForm.propTypes = {
  modal: PropTypes.bool,
  ref: PropTypes.string,
};
