import { Provider as CommunityProvider } from './community';
import Login from './login';
import Global from './global';
import Hud from './hud';
import Join from './join';
import { Provider as CommentsProvider } from './comments';
import { Provider as InvitationProvider } from './invitation';

const providers = [
  Global.Provider,
  Login.Provider,
  Hud.Provider,
  Join.Provider,
  CommunityProvider,
  CommentsProvider,
  InvitationProvider,
];

export default providers;
