import {
  ADD_COMMENT,
  APPEND_COMMENTS,
  DESTROY_COMMENT,
  RESET_COMMENTS,
  UPDATE_COMMENT,
} from './actionType';

export function appendComments(messages) {
  return {
    type: APPEND_COMMENTS,
    params: messages,
  };
}

export function resetComments(post) {
  return {
    type: RESET_COMMENTS,
    params: post,
  };
}

export function addComment(comment) {
  return {
    type: ADD_COMMENT,
    params: { comment },
  };
}

export function destroyComment(comment) {
  return {
    type: DESTROY_COMMENT,
    params: { comment },
  };
}

export function updateComment(comment) {
  return {
    type: UPDATE_COMMENT,
    params: { comment },
  };
}
