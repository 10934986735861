import { RestLink } from 'apollo-link-rest';
import { apiDefaultHeaders } from '../services/request_header_service';

/**
 * Define the RESTFul API link, for general RESTFul API request.
 *
 * Refer to: https://www.apollographql.com/docs/link/links/rest/
 */
const RestApiLink = new RestLink({
  uri: process.env.REACT_APP_REST_API_BASE_URL || '',
  headers: apiDefaultHeaders(),
});

export default RestApiLink;
