import {
  APPEND_POSTS,
  CREATE_POSTS,
  MARK_AS_READ,
  REQUEST_COMMUNITY,
  SET_CURRENT_ACTIVE_POST,
  SET_SHOW_POST_DETAIL_COLUMN,
  SET_TOPIC_IDS,
  UNSHIFT_POST,
  UPDATE_COMMENTS_COUNT,
  UPDATE_POST,
  UPDATE_SINGLE_POST_REACTIONS,
} from './actionType';

export function requestCommunity(community) {
  return {
    type: REQUEST_COMMUNITY,
    params: community,
  };
}

export function setTopicIds(selectedTopicIds) {
  return {
    type: SET_TOPIC_IDS,
    params: selectedTopicIds,
  };
}

export function appendPosts(appendPosts) {
  return {
    type: APPEND_POSTS,
    params: appendPosts,
  };
}

export function createPosts(newPosts) {
  return {
    type: CREATE_POSTS,
    params: newPosts,
  };
}

export function setCurrentActivePost(activePost) {
  return {
    type: SET_CURRENT_ACTIVE_POST,
    params: activePost,
  };
}

export function setShowPostDetailColumn(showPostDetailColumn) {
  return {
    type: SET_SHOW_POST_DETAIL_COLUMN,
    params: showPostDetailColumn,
  };
}

export function updateSinglePostReactions(params) {
  return {
    type: UPDATE_SINGLE_POST_REACTIONS,
    params,
  };
}

export function unshiftPost(post) {
  return {
    type: UNSHIFT_POST,
    params: post,
  };
}

export function updateCommentsCount(post_id, count) {
  return {
    type: UPDATE_COMMENTS_COUNT,
    params: { post_id, count },
  };
}

export function setLastReadAt(lastReadAt) {
  return {
    type: MARK_AS_READ,
    params: { lastReadAt },
  };
}

export function updatePost(newPost) {
  return {
    type: UPDATE_POST,
    params: { newPost },
  };
}
