import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './post-box.module.scss';

import SpaceInput from '../SpaceInput';
import PostBoxExpanded from './PostBoxExpanded';
import Avatar from '../Avatar';
import { useGlobalContext } from '../../store/global';
import TokenizedJoinModal from '../modals/TokenizedJoinModal';

export default function PostBox({ community }) {
  const [inputValue, setInputValue] = useState('');
  const { state: global } = useGlobalContext();

  const [showJoinModal, setShowJoinModal] = useState(false);

  const _onChange = e => {
    setInputValue(e.target.value);
  };

  const _renderComponent = () => {
    const {
      state: { current_login_user },
    } = useGlobalContext();

    if (global.isAuthorized && !global.isInvitation) {
      return <PostBoxExpanded community={community} />;
    } else {
      return (
        <div
          className={styles['input-wrapper']}
          onClick={() => setShowJoinModal(true)}
        >
          <SpaceInput
            value={inputValue}
            onChange={_onChange}
            placeholder={'Join to post'}
            prefix={
              <Avatar
                user={current_login_user}
                size={30}
                includeBorder={false}
              />
            }
            prefixClassName={styles['prefix']}
            inputClassName={styles['input']}
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className="pt-3 pb-3 border-bottom bg-dark">
        {_renderComponent()}
      </div>
      {showJoinModal ? (
        <TokenizedJoinModal onClose={() => setShowJoinModal(false)} />
      ) : null}
    </>
  );
}

PostBox.propTypes = {
  community: PropTypes.object,
};
