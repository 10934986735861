import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './space-select.module.scss';
import SpaceInput from './SpaceInput';
import Polygon from '../assets/icon/polygon.svg';
import PolygonBlack from '../assets/icon/Polygon_black.svg';
import SpaceOption from './SpaceOption';
import ClassNames from 'classnames';
import { getSpaceIconBackground } from '../helpers/community_helpers';
import { useCommunityContext } from '../store/community';
import HobnobModal from './HobnobModal';
import Button from './Button';

import Picker from 'rmc-picker';
import 'rmc-picker/assets/index.css';
import { useWindowSize } from '../hooks/WindowHooks';
import { BREAKPOINT_SM } from '../consts';

const containerStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  margin: '0',
  padding: '0',
  borderRadius: 0,
  minHeight: 'auto',
};

export default function SpaceSelect(props) {
  const {
    options = { value: '', text: '' },
    onChange,
    value,
    className,
    placement,
    theme,
    inputClassName,
    style,
  } = props;

  const [focus, setFocus] = useState(false);
  const filterOption = options.filter(item => item.value === value)[0];
  const defaultValue =
    filterOption && filterOption.text ? filterOption.text : '';
  const [inputValue, setInputValue] = useState(defaultValue);
  const [pickerValue, setPickerValue] = useState(defaultValue);
  const [active, setActive] = useState(value);
  const innerWidth = useWindowSize().width;

  const selectRef = useRef(null);
  const selectContainerRef = useRef(null);
  const [clientWidth, setClientWidth] = useState('auto');
  useEffect(() => {
    if (selectContainerRef.current && selectContainerRef.current.children[0]) {
      const childrenClientWidth =
        selectContainerRef.current.children[0].clientWidth;
      setClientWidth(childrenClientWidth + 20);
    } else {
      setClientWidth('auto');
    }
  }, [innerWidth, selectContainerRef]);

  const {
    state: { community },
  } = useCommunityContext();
  const activeItemBorderColor = getSpaceIconBackground(community);

  useEffect(() => {
    document.addEventListener('click', outDivClickHandler);
    return () => document.removeEventListener('click', outDivClickHandler);
  });

  const outDivClickHandler = e => {
    const target = e.target;
    if (
      selectRef &&
      selectRef.current &&
      !selectRef.current.contains(target) &&
      innerWidth > BREAKPOINT_SM
    ) {
      setFocus(false);
    }
  };

  const _onChange = e => {
    setInputValue(e.target.value);
  };

  const _onClick = (value, label) => {
    setInputValue(label);
    onChange(value);
    setActive(value);
    setFocus(false);
  };

  const _dealClassName = () => {
    if (placement === 'top-end') {
      return styles['select-dropdown-top'];
    }
    return null;
  };
  const selectDropDownClass = _dealClassName();

  const _cancel = () => {
    setFocus(!focus);
  };

  const _ok = () => {
    const value = options.filter(item => item.value === pickerValue)[0].text;
    setInputValue(value);
    onChange(pickerValue);
    setFocus(!focus);
  };
  const _onPickerChange = value => {
    setPickerValue(value);
  };
  const _onClickSelect = () => {
    setFocus(!focus);
  };

  const _onCloseModal = () => {
    setFocus(!focus);
  };

  const _renderPickerModal = () => {
    return (
      <HobnobModal
        show={focus}
        onClose={_onCloseModal}
        showCancelIcon={false}
        containerStyles={containerStyle}
      >
        <li className={ClassNames(styles['button-wrapper'])}>
          <Button
            type={'text'}
            filled={false}
            className={styles['button']}
            onClick={_cancel}
          >
            Cancel
          </Button>
          <Button
            type={'text'}
            filled={false}
            className={styles['button']}
            onClick={_ok}
          >
            ok
          </Button>
        </li>
        <Picker selectedValue={pickerValue} onValueChange={_onPickerChange}>
          {options.map((item, index) => {
            return (
              <Picker.Item value={item.value} key={index}>
                {item.text}
              </Picker.Item>
            );
          })}
        </Picker>
      </HobnobModal>
    );
  };
  const _renderSelector = () => {
    return (
      <>
        <ul
          className={ClassNames(
            styles['select-dropdown'],
            'd-none',
            'd-sm-block',
            selectDropDownClass
          )}
          ref={selectContainerRef}
          style={{ opacity: focus ? 1 : 0, zIndex: focus ? 2 : -1 }}
        >
          {options.map((item, index) => {
            return (
              <SpaceOption
                className={ClassNames(
                  `${styles['select-option']} ${active === item.value &&
                    styles['active']}`
                )}
                key={index}
                value={item.value}
                label={item.text}
                onClick={_onClick}
              >
                <div
                  style={{ backgroundColor: activeItemBorderColor }}
                  className={styles['active-option']}
                />
              </SpaceOption>
            );
          })}
        </ul>
        <div
          className={ClassNames(
            placement === 'top-end'
              ? styles['triangle-top']
              : styles['triangle'],
            'd-none',
            'd-sm-block'
          )}
          style={{ opacity: focus ? 1 : 0, zIndex: focus ? 2 : -1 }}
        />
      </>
    );
  };

  return (
    <div
      className={ClassNames(styles['select-wrapper'], className)}
      ref={selectRef}
      style={{ width: clientWidth, flex: `1 1 ${clientWidth}px`, ...style }}
    >
      <SpaceInput
        className={ClassNames(styles['select-input'], inputClassName)}
        inputClassName={styles['input']}
        value={inputValue}
        suffix={
          <img
            src={theme === 'dark' ? Polygon : PolygonBlack}
            className={ClassNames(
              styles['select__caret'],
              styles['icon-arrow-up'],
              { [styles['is-reverse']]: focus }
            )}
            alt={'arrow-up'}
          />
        }
        suffixClassName={styles['suffix']}
        readOnly="readonly"
        placeholder={'select'}
        theme={theme}
        onChange={_onChange}
        onClick={_onClickSelect}
      />
      {innerWidth <= BREAKPOINT_SM ? _renderPickerModal() : _renderSelector()}
    </div>
  );
}

SpaceSelect.defaultProps = {
  placement: 'bottom-end',
  theme: 'light',
};

SpaceSelect.propTyoes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placement: PropTypes.string,
  theme: PropTypes.string,
  style: PropTypes.object,
};
