import React, { useState } from 'react';

import SpaceSelect from '../SpaceSelect';

import { setTopicIds } from '../../store/community/actions';

import { useCommunityContext } from '../../store/community';

import styles from './topic-filter-selection.module.scss';

const defaultTopics = [
  {
    id: '',
    name: 'All Posts',
  },
];

export default function TopicFilterSelection({ community }) {
  const { state, dispatch } = useCommunityContext();
  const { topicIds: topic_ids } = state;

  const { topics } = community;
  const hasTopics = topic_ids.length && topic_ids.length < topics.length;
  const defaultValue = hasTopics ? topic_ids[0] : '';
  const [value, setValue] = useState(defaultValue);

  const _onChange = value => {
    setValue(value);

    if (value === '') {
      dispatch(setTopicIds([]));
    } else {
      dispatch(setTopicIds([value]));
    }
  };

  const _topicsOptions = () => {
    const allTopics = defaultTopics.concat(topics);
    return allTopics.map(({ id, name }) => {
      if (id) {
        return {
          value: id,
          text: `# ${name}`,
        };
      }
      return {
        value: id,
        text: name,
      };
    });
  };

  return (
    <div className="d-flex d-sm-none justify-content-between align-items-center p-3 bg-light">
      <h1 className="mb-0 mr-2">Posts</h1>
      <SpaceSelect
        className={styles['selector']}
        inputClassName={styles['base-select']}
        value={value}
        onChange={_onChange}
        options={_topicsOptions()}
      />
    </div>
  );
}
