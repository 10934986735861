import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './pages/anonymous';
import InvitationHome from './pages/invitation/index';
import Provider from './store/provider';
import PageNotFound from './pages/404/PageNotFound';
import { GlobalPage } from './pages/GlobalPage';

const WebAppRouter = () => {
  return (
    <Provider>
      <Router>
        <Switch>
          <Route
            exact
            path="/spaces/:space_slug"
            render={props => (
              <GlobalPage {...props}>
                <Home {...props} />
              </GlobalPage>
            )}
          />
          <Route
            path="/spaces/:space_slug/:invitation_token"
            render={props => (
              <GlobalPage {...props}>
                <InvitationHome {...props} />
              </GlobalPage>
            )}
          />
          <Route path="/*" component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default WebAppRouter;
