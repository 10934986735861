import PropTypes from 'prop-types';
import React from 'react';

import {
  EMAIL_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD,
} from '../../services/contact_methods';

export default class LoginConfirmationMessage extends React.Component {
  _formatPhoneNumber(phoneNumber) {
    const areaCode = phoneNumber.substr(1, 3);
    const prefix = phoneNumber.substr(4, 3);
    const suffix = phoneNumber.substr(7, 4);

    return `(${areaCode}) ${prefix}-${suffix}`;
  }

  _renderDestination() {
    const { contactMethod, loginForm } = this.props;
    switch (contactMethod && contactMethod.type) {
      case PHONE_NUMBER_CONTACT_METHOD:
        return this._formatPhoneNumber(loginForm.phoneNumber);
      case EMAIL_CONTACT_METHOD:
        if (loginForm.useMaskedEmail) {
          return loginForm.maskedEmailAddress;
        } else {
          return loginForm.emailAddress;
        }
      default:
        return this._formatPhoneNumber(loginForm.phoneNumber);
    }
  }

  render() {
    return (
      <div className="mb-4">
        A code was sent to <strong>{this._renderDestination()}</strong>.
        <br />
        Enter it here to verify your number:
      </div>
    );
  }
}

LoginConfirmationMessage.propTypes = {
  contactMethod: PropTypes.object,
  loginForm: PropTypes.object.isRequired,
};
