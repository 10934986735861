import React from 'react';
import PropTypes from 'prop-types';

import HobnobModal from '../HobnobModal';
import styles from './image-modal.module.scss';

export default function ImageModal(props) {
  const { show, onClose, imageModalUrl } = props;

  const containerStyles = {
    backgroundColor: 'transparent',
    padding: 0,
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    margin: 0,
  };
  return (
    <HobnobModal
      show={show}
      onClose={onClose}
      showCancelIcon={false}
      containerStyles={containerStyles}
      contentClassName="h-100"
    >
      <div className={styles['modal']} onClick={onClose}>
        <img src={imageModalUrl} className={styles['image']} alt="preview" />
      </div>
    </HobnobModal>
  );
}

ImageModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  imageModalUrl: PropTypes.string,
};
