import React, { useState } from 'react';
import Proptypes from 'prop-types';
import ClassNames from 'classnames';

import SpaceInput from '../SpaceInput';
import styles from './user-name-form.module.scss';
import Button from '../Button';

import {
  EMAIL_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD,
  VALID_COUNTRY_CODES,
} from '../../services/contact_methods';
import SpaceSelect from '../SpaceSelect';

function _contactMethodType(props) {
  const { contactMethod } = props;
  return contactMethod ? contactMethod.type : PHONE_NUMBER_CONTACT_METHOD;
}

export default function UserNameForm(props) {
  const { fields, updateForm, loading, isError } = props;

  const [showErrors, setShowErrors] = useState(false);

  function _countryCodeOptions() {
    return VALID_COUNTRY_CODES.map(({ code, value }) => {
      return {
        value: code,
        text: `${code}+${value}`,
      };
    });
  }

  function _setFirstName(e) {
    updateForm({ firstName: e.target.value });
  }

  function _setLastName(e) {
    updateForm({ lastName: e.target.value });
  }

  function _setCountryCode(value) {
    updateForm({ countryCode: value });
  }

  function _setPhoneNumber(e) {
    const value = '1' + e.target.value.replace(/\D/g, '');
    updateForm({ phoneNumber: value, formattedPhoneNumber: e.target.value });
  }

  function _validFirstName() {
    return !showErrors || fields.firstName !== '';
  }

  function _formIsValid() {
    return fields.firstName !== '' && _contactMethodIsValid();
  }

  function _phoneNumberValid() {
    return fields.phoneNumber.length === 11;
  }

  function _countryCodeValid() {
    const { countryCode } = fields;
    const validCodes = VALID_COUNTRY_CODES.map(c => c.code);
    return validCodes.indexOf(countryCode) >= 0;
  }

  function _contactMethodIsValid() {
    switch (_contactMethodType(props)) {
      case EMAIL_CONTACT_METHOD:
        // FIXME: we should do at least some validation
        return true;
      case PHONE_NUMBER_CONTACT_METHOD:
        return _phoneNumberValid() && _countryCodeValid();
      default:
        return _phoneNumberValid() && _countryCodeValid();
    }
  }

  function _onSubmit(e) {
    const { submitForm } = props;
    e.preventDefault();
    if (_formIsValid()) {
      submitForm();
    } else {
      setShowErrors(true);
    }
  }

  return (
    <form onSubmit={_onSubmit}>
      <div className="d-flex mb-3">
        <SpaceInput
          valid={_validFirstName()}
          value={fields.firstName}
          onChange={_setFirstName}
          className={ClassNames(`flex-grow-1 mr-2 ${styles['input']}`)}
          placeholder="First"
        />
        <SpaceInput
          value={fields.lastName}
          valid={true}
          onChange={_setLastName}
          className={ClassNames(`flex-grow-1 ${styles['input']}`)}
          placeholder="Last"
        />
      </div>
      <div className="mb-3">
        <SpaceInput
          value={fields.formattedPhoneNumber}
          type="tel"
          className={ClassNames(`${styles['input']}`)}
          valid={!isError}
          placeholder="Phone Number"
          onChange={_setPhoneNumber}
        >
          <SpaceSelect
            onChange={_setCountryCode}
            value={fields.countryCode}
            className={ClassNames(styles['country-code'])}
            inputClassName={styles['country-code-input']}
            options={_countryCodeOptions()}
            required
          />
        </SpaceInput>
      </div>
      <Button
        disabled={!_formIsValid()}
        loading={loading}
        className={ClassNames(`${styles['button']} mb-3`)}
        onClick={_onSubmit}
      >
        next
      </Button>
      <div className={styles['text-prompt']}>
        Don’t worry, we’ll never spam or share your number.
      </div>
    </form>
  );
}

UserNameForm.propTypes = {
  loading: Proptypes.bool,
  fields: Proptypes.object.isRequired,
  updateForm: Proptypes.func.isRequired,
  submitForm: Proptypes.func.isRequired,
};
