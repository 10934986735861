import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './post-box-editor.module.scss';
import PostBoxOperationBar from './PostBoxOperationBar';

import SpaceInput from '../SpaceInput';
import Collapse from '../../assets/icon/collapse.svg';
import Avatar from '../Avatar';
import { useGlobalContext } from '../../store/global';

const GothamFontFamily = 'Gotham SSm A, Gotham SSm B';

export default function PostBoxEditor(props) {
  const {
    state: { current_login_user },
  } = useGlobalContext();

  const { newPost, updatePost } = props;

  //title
  function _onTitleChange(e) {
    updatePost({ title: e.target.value });
  }

  //content
  function _onContentChange(e) {
    updatePost({
      message: { message_parts: [{ body: e.target.value, type: 'text' }] },
    });
  }

  const { needCollapse, onCollapse } = props;
  if (JSON.stringify(current_login_user) === '{}') {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className={ClassNames(
          `d-flex flex-grow-1 ${needCollapse ? styles['is-collapse'] : ''}`
        )}
      >
        <Avatar
          url={current_login_user.avatar.small_url_2x}
          user={current_login_user}
          size={40}
          includeBorder={false}
        />
        <div
          className={ClassNames(
            `flex-grow-1 d-flex flex-column ${styles['text-area-wrapper']}`
          )}
        >
          {/*  title*/}
          <SpaceInput
            className={ClassNames(`${styles['bg-transparent']}`)}
            inputClassName={styles['title-input']}
            style={{
              fontSize: needCollapse ? '22px' : '16px',
              fontFamily: needCollapse ? GothamFontFamily : '',
            }}
            placeholder="Your Post Title"
            value={newPost.title}
            onChange={_onTitleChange}
          />
          {/*text*/}
          <SpaceInput
            id="textareaRef"
            type="textarea"
            autosize={!needCollapse}
            className={ClassNames(`flex-grow-1 ${styles['bg-transparent']}`)}
            inputClassName={styles['content-input']}
            placeholder="Add more text here! "
            value={newPost.message.message_parts[0].body}
            rows={2}
            onChange={_onContentChange}
          />
        </div>
        {needCollapse ? (
          <img
            src={Collapse}
            alt={'expand'}
            className={styles['collapse-icon']}
            onClick={onCollapse}
          />
        ) : null}
      </div>
      <PostBoxOperationBar />
    </React.Fragment>
  );
}

PostBoxEditor.defaultProps = {
  needCollapse: false,
};

PostBoxEditor.propTypes = {
  needCollapse: PropTypes.bool,
  onCollapse: PropTypes.func,
  newPost: PropTypes.object,
  updatePost: PropTypes.func,
};
