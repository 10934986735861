import { HIDE_LOGIN_MODAL, SHOW_LOGIN_MODAL } from './actionType';

export const loginInit = {
  showLoginModal: false,
};

const reducer = (state = loginInit, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_LOGIN_MODAL:
      return { ...state, showLoginModal: true };
    case HIDE_LOGIN_MODAL:
      return { ...state, showLoginModal: false };
    default:
      return state;
  }
};

export default reducer;
