import React, { useState } from 'react';

import MemberOutlineIcon from '../../assets/icon/member_outline_icon.svg';
import Button from '../Button';
import HobnobModal from '../HobnobModal';
import styles from './members.module.scss';

import moment from 'moment';
import { useGlobalContext } from '../../store/global';
import { useCommunityContext } from '../../store/community';
import { COMMUNITY_INVITATIONS_QUERY } from '../../graphql/Invitations';
import { useQuery } from 'react-apollo-hooks';
import { fullName } from '../../helpers/user_helpers';
import Avatar from '../Avatar';
import _ from 'lodash';

const displayRoles = [
  {
    role: 'admin',
    displayName: 'Admins',
  },
  {
    role: 'moderator',
    displayName: 'Moderators',
  },
  {
    role: 'member',
    displayName: 'Members',
  },
];

function _renderLabel(member_count) {
  if (member_count <= 1) {
    return `${member_count} member`;
  } else {
    return `${member_count.toLocaleString()} members`;
  }
}

function Members() {
  const { state: globalState } = useGlobalContext();
  const {
    state: { community },
  } = useCommunityContext();
  const { isInvitation } = globalState;
  const { id: communityId, member_count, community_members } = community;
  const [showMembers, setShowMembers] = useState(false);

  const _showMembers = () => {
    setShowMembers(!!community_members);
  };

  const _closeMembersModal = () => {
    setShowMembers(false);
  };

  // it will be ignored if it is an invitation request
  const communityInvitationsOptions = {
    variables: {
      communityId,
    },
    skip: isInvitation,
  };
  const { data } = useQuery(
    COMMUNITY_INVITATIONS_QUERY,
    communityInvitationsOptions
  );

  // render all invited members
  const _renderInvitedMembers = () => {
    if (data && data.community_invitations_query) {
      const {
        community_invitations_query: { community_invitations },
      } = data;

      const displayInvitedMembers = community_invitations.filter(invitation => {
        const { join_state } = invitation;
        return join_state === 'undecided' || join_state === 'viewed';
      });

      if (!displayInvitedMembers || displayInvitedMembers.length === 0) {
        return null;
      }

      const sortedInvitedMembers = _.sortBy(displayInvitedMembers, [
        member => member.user.first_name,
      ]);
      return (
        <ul key={'invited'} className={styles['member-ul']}>
          <li className={styles['role-label']} key={'invited'}>
            Invited Members
          </li>
          {sortedInvitedMembers.map(invitation => {
            const { created_by_user, created_at, user } = invitation;
            return (
              <li key={invitation.id} className={styles['role-item']}>
                <Avatar user={user} size={40} />
                <div className="ml-2 text-left">
                  <div>
                    <strong>{fullName(user)}</strong>
                  </div>
                  <div className={styles['time']}>
                    Invited {moment(created_at).format('M/d/YY')} by{' '}
                    {fullName(created_by_user)}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
    return null;
  };

  // render other roles: admin, moderator, members
  const _renderByRole = displayRole => {
    const { role, displayName } = displayRole;
    if (!community_members || community_members.length === 0) {
      return null;
    }

    const roleHeader = (
      <li className={styles['role-label']} key={role}>
        {displayName}
      </li>
    );

    const members = community_members
      .filter(member => member.joined_at != null)
      .filter(member => member.role.toLowerCase() === role.toLowerCase());

    const sortedMembers = _.sortBy(members, [member => member.name]);
    const displayMembers = sortedMembers.map(member => (
      <li key={member.id} className={styles['role-item']}>
        <Avatar user={member} size={40} />
        <div className="ml-2 text-left">
          <div className={styles['name']}>{member.name}</div>
          <div className={styles['time']}>
            Joined {moment(member.joined_at).format('M/d/YY')}
          </div>
        </div>
      </li>
    ));

    if (displayMembers && displayMembers.length > 0) {
      return (
        <ul key={role} className={styles['member-ul']}>
          {[roleHeader, ...displayMembers]}
        </ul>
      );
    }

    return null;
  };

  const containerStyles = {
    maxHeight: '100%',
    overflow: 'auto',
    margin: '0',
    padding: '0 0 30px',
    borderRadius: '0',
  };

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <>
      <div className={styles['member-wrapper']}>
        <img className="mr-2" src={MemberOutlineIcon} alt="memberOutline" />
        <Button type={'text'} className={styles['text']} onClick={_showMembers}>
          {_renderLabel(member_count)}
        </Button>
      </div>
      <HobnobModal
        onClose={_closeMembersModal}
        show={showMembers}
        style={style}
        containerStyles={containerStyles}
        containerClassName={styles['members_modal']}
        cancelClassName={styles['modal-cancel']}
        cancelWrapperClassName={styles['cancel-wrapper']}
      >
        {displayRoles.map(item => {
          return _renderByRole(item);
        })}

        {_renderInvitedMembers()}
      </HobnobModal>
    </>
  );
}

export default Members;
