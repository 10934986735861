import React from 'react';
import ClassNames from 'classnames';
import styles from './Loading.module.scss';
// https://facebook.github.io/create-react-app/docs/adding-images-fonts-and-files#adding-svgs
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';

const Loading = ({ theme = 'bg-dark' }) => (
  <div className={styles['Loading']}>
    <div className={ClassNames(styles['container'], theme)}>
      <LoadingIcon
        className={ClassNames(`${styles['loading-icon']} indicator`)}
      />
    </div>
  </div>
);

export default Loading;
