import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import { useQuery } from 'react-apollo-hooks';

import Post from './Post';

import { QUERY_COMMUNITY_POSTS_BY_PAGE } from '../../graphql/Community';
import { useError } from '../../hooks/ErrorHooks';

import { useWindowSize } from '../../hooks/WindowHooks';
import LoadingIndicator from '../LoadingIndicator';
import { useCommunityContext } from '../../store/community';

import {
  appendPosts,
  createPosts,
  setCurrentActivePost,
  setShowPostDetailColumn,
  updateSinglePostReactions,
} from '../../store/community/actions';

import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../consts';
import { useCommentsContext } from '../../store/comments';
import { resetComments } from '../../store/comments/actions';
import { networkOnlyFetchPolicy } from '../../helpers/fetch_policy_helpers';

function PostList(props) {
  const innerWidth = useWindowSize().width;

  const { community } = props;

  const [pageInfo, setPageInfo] = useState(props.pageInfo);
  const [endCursor, setEndCursor] = useState('');
  // https://github.com/CassetteRocks/react-infinite-scroller/issues/149#issuecomment-466881102
  const [initialLoad, setInitialLoad] = useState(true);
  const [firstRender, setFirstRender] = useState(true);

  const {
    state: communityState,
    dispatch: communityDispatch,
  } = useCommunityContext();
  const { dispatch: commentDispatch } = useCommentsContext();
  const { topicIds, posts: postList } = communityState;

  const options = {
    variables: {
      ids: [community.id],
      topics: topicIds.length ? topicIds : null,
      postsLimit: 4,
      before: endCursor,
    },
    skip: !community.id,
    fetchPolicy: networkOnlyFetchPolicy(),
  };
  const { error, data } = useQuery(QUERY_COMMUNITY_POSTS_BY_PAGE, options);
  useError(error);

  useEffect(() => {
    if (data) {
      const { communities } = data;
      const { posts_connection } = communities[0];
      const { posts, page_info } = posts_connection;
      if (endCursor !== '') {
        communityDispatch(appendPosts(posts));
      } else {
        communityDispatch(createPosts(posts));
      }

      setPageInfo(page_info);
      setInitialLoad(true);
    }
  }, [data, endCursor, communityDispatch]);

  const _loadMore = () => {
    setInitialLoad(false);
    const { end_cursor, has_next_page } = pageInfo;
    if (has_next_page) {
      // due to the first time query will be override,
      // so set the end_cursor to empty to re-query the first page data.
      setEndCursor(firstRender ? '' : end_cursor);
      setFirstRender(false);
    }
  };

  const _onClick = post => {
    communityDispatch(setCurrentActivePost(post));
    commentDispatch(resetComments());
    if (innerWidth < BREAKPOINT_LG) {
      communityDispatch(setShowPostDetailColumn(true));
      window.scrollTo(0, 0);
    }
  };

  // update the post list reaction count if the post update the reaction
  const _updatePostReaction = (post_id, reactions) => {
    communityDispatch(updateSinglePostReactions({ post_id, reactions }));
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={_loadMore}
      hasMore={pageInfo.has_next_page}
      loader={<LoadingIndicator key={0} />}
      useWindow={innerWidth < BREAKPOINT_SM}
      initialLoad={initialLoad}
      threshold={250}
    >
      {postList.map(item => {
        return (
          <Post
            key={item.id}
            post={item}
            onClick={_onClick}
            onReactionUpdate={_updatePostReaction}
          />
        );
      })}
    </InfiniteScroll>
  );
}

PostList.propTypes = {
  pageInfo: PropType.object,
};

export default PostList;
