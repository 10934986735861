// community
export const REQUEST_COMMUNITY = 'REQUEST_COMMUNITY';

// post topics
export const SET_TOPIC_IDS = 'SET_TOPIC_IDS';

// post list
export const APPEND_POSTS = 'APPEND_POSTS';
export const CREATE_POSTS = 'CREATE_POSTS';
export const UNSHIFT_POST = 'UNSHIFT_POST';

// post detail
export const SET_CURRENT_ACTIVE_POST = 'SET_CURRENT_ACTIVE_POST';
export const SET_SHOW_POST_DETAIL_COLUMN = 'SET_SHOW_POST_DETAIL_COLUMN';
export const UPDATE_SINGLE_POST_REACTIONS = 'UPDATE_SINGLE_POST_REACTIONS';
export const MARK_AS_READ = 'MARK_AS_READ';
export const UPDATE_POST = 'UPDATE_POST';

// post comments
export const UPDATE_COMMENTS_COUNT = 'UPDATE_COMMENTS_COUNT';
