import QueryString from 'query-string';

const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';
const GOOGLE_BROWSER_API_KEY = process.env.REACT_APP_GOOGLE_BROWSER_API_KEY;
const APPLE_MAPS_URL = 'http://maps.apple.com/';

export function staticMapImageUrl(lat, lng, zoom = 14) {
  const latlng = `${lat},${lng}`;
  const mapParams = {
    center: latlng,
    key: GOOGLE_BROWSER_API_KEY,
    maptype: 'roadmap',
    markers: `icon:${process.env.ASSET_HOST}/assets/map-pin.png|scale=1|${latlng}`,
    scale: 2,
    size: '320x320',
    zoom,
  };

  return `${BASE_URL}?${QueryString.stringify(mapParams)}`;
}

export function mapUrl(lat, lng) {
  return `${APPLE_MAPS_URL}?${QueryString.stringify({ q: latLng(lat, lng) })}`;
}

function latLng(lat, lng) {
  return `${lat},${lng}`;
}
