import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'classnames';
import InputElement from 'react-input-mask';

const phoneMask = '999-999-9999';

export default function PhoneInputElement(props) {
  const { className, valid, value, errorClassName, ...otherProps } = props;

  return (
    <InputElement
      type="tel"
      mask={phoneMask}
      value={value}
      className={ClassNames(className, { [errorClassName]: !valid })}
      {...otherProps}
    />
  );
}

PhoneInputElement.propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  showError: PropTypes.bool,
  value: PropTypes.string,
};
