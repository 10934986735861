import React from 'react';

import PostBox from './PostBox';
import { ReactComponent as EmptyStateMegaphone } from '../../assets/empty/empty_state_megaphone.svg';

import styles from './empty.module.scss';
import TopicFilterSelection from './TopicFilterSelection';

function Empty({ community }) {
  return (
    <div className="d-flex justify-content-center align-content-center flex-column flex-grow-1">
      <TopicFilterSelection community={community} />
      <PostBox community={community} />
      <div className="flex-grow-1 d-flex justify-content-center align-items-center flex-column bg-light">
        <EmptyStateMegaphone className={styles['empty-img']} />
        <p className={styles['empty-text']}>
          Nobody has posted in this topic yet. Be the first and start the
          conversation
        </p>
      </div>
    </div>
  );
}

export default Empty;
