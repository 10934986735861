import React from 'react';

import styles from './download-bar.module.scss';
import LogoApple from '../../assets/icon/logo_apple.svg';
import Button from '../Button';

function DownloadBar() {
  return (
    <div className={styles['wrapper']}>
      <img src={LogoApple} alt={'logoApple'} />
      <span className="ml-3">
        For advanced features like posting photos & GIFs&nbsp;
        <Button
          wrapperElement="a"
          href={process.env.REACT_APP_IOS_APP_DOWNLOAD_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={styles['download-prompt']}
        >
          <strong>Download Our iPhone App.</strong>
        </Button>
      </span>
    </div>
  );
}

export default DownloadBar;
