import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import {
  getSpaceIconBackground,
  sortTopics,
} from '../helpers/community_helpers';

import styles from './post-cell.module.scss';

export default function TopicFilterItems(props) {
  const {
    topics: fullTopics,
    onClick,
    className,
    currentActives = [],
    community,
  } = props;

  const topics = fullTopics.filter(source => !!source.name).sort(sortTopics);
  const activeItemBorderColor = getSpaceIconBackground(community);

  function onClickCell(e, item) {
    onClick(item.id);
  }

  return (
    <ul className={ClassNames(styles['wrapper'], className)}>
      {topics.map(item => {
        return (
          <li
            className={ClassNames(
              `border-bottom d-flex align-items-center ${
                styles['item']
              } ${currentActives.indexOf(item.id) !== -1 && styles['active']}`
            )}
            key={item.id}
            onClick={e => onClickCell(e, item)}
          >
            <div
              style={{ backgroundColor: activeItemBorderColor }}
              className={styles['active-topic']}
            />
            # &nbsp;{item.name}
          </li>
        );
      })}
    </ul>
  );
}

TopicFilterItems.defaultProps = {
  topics: [],
  currentActive: '',
};

TopicFilterItems.propTypes = {
  topics: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onClickAllCell: PropTypes.func,
  className: PropTypes.string,
  currentActives: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
