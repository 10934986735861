import React from 'react';
import AnonymousModal from './AnonymousModal';
import ErrorModal from './ErrorModal';
import { useGlobalContext } from '../../store/global';
import { useHudContext } from '../../store/hud';
import { hideHud } from '../../store/hud/actions';
import { updateGlobalContext } from '../../store/global/actions';
import { setLoginTokens } from '../../helpers/localstorage_helpers';
import { createSocket } from '../../store/socket/reducer';

export default function() {
  const { state: global, dispatch: globalDispatch } = useGlobalContext();
  const { state: hud, dispatch: hudDispatch } = useHudContext();

  // common method used for login/join modal after login success.
  const _loginSuccess = ({ access_token, refresh_token }) => {
    setLoginTokens({ access_token, refresh_token });

    // connect to ws asap we login
    createSocket();

    globalDispatch(
      updateGlobalContext({
        access_token,
        refresh_token,
        isAuthorized: true,
      })
    );
  };

  const _onCloseAnonymousModal = () => {
    // authDispatch(hideAnonymousModal());
  };

  const _onCloseErrorModal = () => {
    hudDispatch(hideHud());
  };
  return (
    <div>
      <AnonymousModal
        show={!global.isAuthorized}
        onClose={_onCloseAnonymousModal}
        onSuccess={_loginSuccess}
      />
      <ErrorModal show={hud.showErrorModal} onClose={_onCloseErrorModal} />
    </div>
  );
}
