import PropTypes from 'prop-types';
import React from 'react';

import { useGlobalContext } from '../store/global';

import styles from './floating-button-spacer.module.scss';

export default function FloatingButtonSpacer(props) {
  const {
    state: { floatingButtonVisible },
  } = useGlobalContext();

  const _className = () => {
    const { alabasterBackground, alwaysShow } = props;
    if (alwaysShow) {
      if (alabasterBackground) {
        return 'container-active-alabaster';
      } else {
        return 'container-active';
      }
    }

    if (floatingButtonVisible) {
      if (alabasterBackground) {
        return 'container-active-alabaster';
      } else {
        return 'container-active';
      }
    } else {
      return 'container';
    }
  };

  return <div className={styles[_className()]} />;
}

FloatingButtonSpacer.propTypes = {
  alabasterBackground: PropTypes.bool,
  floatingButtonVisible: PropTypes.bool,
  alwaysShow: PropTypes.bool,
};
FloatingButtonSpacer.defaultProps = {
  alabasterBackground: false,
  alwaysShow: false,
};
