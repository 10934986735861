import { InMemoryCache } from 'apollo-cache-inmemory';

/**
 * Cache configuration
 *
 * Refer to: https://www.apollographql.com/docs/react/advanced/caching/
 */

const Cache = new InMemoryCache();

export default Cache;
