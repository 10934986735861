import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import HobnobModal from '../HobnobModal';
import styles from './error-modal.module.scss';
import { ReactComponent as EmptyDog } from '../../assets/empty/emptystate_dog.svg';

export default function ErrorModal(props) {
  const { show, onClose } = props;
  const intervalRef = useRef(null);

  useEffect(() => {
    let timer = null;
    if (show) {
      timer = setTimeout(() => {
        onClose && onClose();
      }, 5000);
    }
    intervalRef.current = timer;

    return () => {
      clearTimeout(intervalRef.current);
    };
  }, [show, onClose, intervalRef]);

  const containerStyles = {
    minHeight: 'auto',
  };

  return (
    <HobnobModal
      show={show}
      onClose={onClose}
      containerStyles={containerStyles}
      contentClassName={styles['wrapper']}
    >
      <EmptyDog />
      <h1>Hmm...</h1>
      <div>An error occurred with your request, please try again later</div>
    </HobnobModal>
  );
}
ErrorModal.defaultProps = {
  onClose: () => {},
};

ErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};
