import React, { useReducer, useContext } from 'react';

import reducer, { joinInit } from './reducer';

const Context = React.createContext();

const Provider = props => {
  const [state, dispatch] = useReducer(reducer, joinInit);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useJoinContext = () => useContext(Context);

export default { Context, Provider };
