import React from 'react';
import ClassNames from 'classnames';

import LeftArrow from '../../assets/icon/left_arrow.svg';
import Button from '../Button';
import { useCommunityContext } from '../../store/community';

import styles from './header.module.scss';
import { setShowPostDetailColumn } from '../../store/community/actions';

function Header() {
  const {
    state: { community, currentActivePost },
    dispatch,
  } = useCommunityContext();
  const { name } = community;

  const _onBackClick = () => {
    dispatch(setShowPostDetailColumn(false));
  };

  return (
    <div
      className={ClassNames(
        'd-sm-none border-bottom p-3 d-flex align-items-center'
      )}
    >
      <Button wrapperElement="a" onClick={_onBackClick}>
        <img src={LeftArrow} alt="profile" />
      </Button>
      {/*title*/}
      <span className={'text-center ml-2 flex-grow-1'}>
        <div className={styles['post-title']}>{currentActivePost.title}</div>
        {/*subtitle*/}
        <div className={styles['community-name']}>
          <i>{name}</i>
        </div>
      </span>
    </div>
  );
}

export default Header;
