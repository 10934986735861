import React from 'react';

import Empty from './Empty';
import Container from './Container';
import Loading from '../Loading';
import { useQuery } from 'react-apollo-hooks';
import { COMMUNITY_BY_IDS } from '../../graphql/Community';
import { useError } from '../../hooks/ErrorHooks';
import { useCommunityContext } from '../../store/community';
import { networkOnlyFetchPolicy } from '../../helpers/fetch_policy_helpers';
import { filterDestroyedPosts } from '../../helpers/community_helpers';

function Content() {
  const { state: communityState } = useCommunityContext();
  const { community, topicIds } = communityState;

  // query the community
  const options = {
    variables: {
      ids: [community.id],
      topics: topicIds.length ? topicIds : null,
      postLimit: 4,
    },
    fetchPolicy: networkOnlyFetchPolicy(),
    skip: !community.id,
  };
  const { loading, error, data } = useQuery(COMMUNITY_BY_IDS, options);
  useError(error);

  if (loading || !data) {
    return <Loading theme={'bg-white'} />;
  }

  const { communities } = data;
  if (!communities || communities.length !== 1) {
    return <Loading theme={'bg-white'} />;
  }

  const { posts_connection } = communities[0];
  const { posts, page_info: pageInfo } = posts_connection;

  const postList = filterDestroyedPosts(posts);
  if (postList.length) {
    return <Container community={community} pageInfo={pageInfo} />;
  } else {
    return <Empty community={community} />;
  }
}

export default Content;
