import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import MenuIcon from '../../assets/icon/Menu.svg';
import styles from './top-bar.module.scss';

import UserStatusHeader from './UserStatusHeader';

import { useCommunityContext } from '../../store/community';
import { useGlobalContext } from '../../store/global';
import AuthorizedUserStatus from './AuthorizedUserStatus';

function TopBar(props) {
  const { onClick, className } = props;

  const {
    state: { community },
  } = useCommunityContext();
  const { state: global } = useGlobalContext();
  const {
    state: { showPostDetailColumn },
  } = useCommunityContext();

  function _renderUserStatus() {
    if (global.isAuthorized && !global.isInvitation) {
      return (
        <div
          className={`col-sm-8 col-xl-6 border-bottom ${styles['box-avatar']} ${
            showPostDetailColumn ? 'd-none d-sm-flex' : ''
          }`}
        >
          <AuthorizedUserStatus />
        </div>
      );
    } else if (global.isInvitation) {
      return (
        <div
          className={`col-sm-8 col-xl-6 border-bottom pr-3 pl-3 ${
            styles['box-user-status']
          }`}
        >
          <UserStatusHeader />
        </div>
      );
    }

    return null;
  }

  const { name } = community;

  return (
    <div className={ClassNames(`container-fluid ${className}`)}>
      <div
        className={ClassNames(
          `row flex-wrap-reverse flex-sm-wrap align-items-center position-relative`
        )}
      >
        <div
          className={`col-sm-4 col-xl-6 border-bottom pr-3 pl-3 ${
            styles['box-space-name']
          } ${
            global.isAuthorized && !global.isInvitation
              ? styles['is-authorized']
              : ''
          } ${showPostDetailColumn ? 'd-none d-sm-block' : ''}`}
        >
          <img
            src={MenuIcon}
            className={ClassNames(styles['menu-icon'])}
            onClick={onClick}
            alt="Menu"
          />
          <h2 className={ClassNames(styles['space-name'])} title={name}>
            {name}
          </h2>
        </div>
        {_renderUserStatus()}
      </div>
    </div>
  );
}

TopBar.propType = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default TopBar;
