import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import HobnobModal from '../HobnobModal';
import styles from './error-modal.module.scss';
import Button from '../Button';
import { ReactComponent as EmptyDog } from '../../assets/empty/emptystate_dog.svg';

export default function UnsavedChangesModal(props) {
  const { show, onClose, sureClose } = props;

  return (
    <HobnobModal
      show={show}
      onClose={onClose}
      contentClassName={styles['wrapper']}
    >
      <EmptyDog />
      <h1>Unsaved Changes</h1>
      <div>
        Are you sure you want to close your post? Your changes will not be
        saved.
      </div>
      <Button
        className={ClassNames(`mt-4 ${styles['button']}`)}
        onClick={sureClose}
      >
        Yes, close
      </Button>
      <br />
      <Button
        type={'text'}
        className={ClassNames(
          `${styles['cancel-button']} text-decoration-none`
        )}
        onClick={onClose}
      >
        cancel
      </Button>
    </HobnobModal>
  );
}

UnsavedChangesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  sureClose: PropTypes.func,
};
