import React, { useEffect } from 'react';
import PropType from 'prop-types';
import ClassNames from 'classnames';

import styles from './post.module.scss';

import MessageLined from '../../assets/icon/messages_lined.svg';
import NewTag from '../../assets/icon/new_tag.svg';

import { fullName } from '../../helpers/user_helpers';

import { pluralize } from '../../helpers/utils';

import { formatDateString } from '../../helpers/date_helpers';
import Avatar from '../Avatar';
import { useCommunityContext } from '../../store/community';
import Reactions from '../Reactions';
import TextMessage from './TextMessage';
import LinkPreview from './LinkPreview';
import { subscribe } from '../../store/socket/reducer';
import {
  LISTEN_NEW_MESSAGE,
  MESSAGE_DESTROY,
  MESSAGE_UPDATE,
} from '../../services/room_channel_types';
import { updateCommentsCount, updatePost } from '../../store/community/actions';
import {
  addComment,
  destroyComment,
  updateComment,
} from '../../store/comments/actions';
import { useCommentsContext } from '../../store/comments';

function Post(props) {
  const { post, onClick, onReactionUpdate } = props;
  const {
    channel,
    message_id,
    message_count,
    message: { reactions = [] },
    community_member,
  } = post;
  const { inserted_at } = post;
  const { writtenNow: showNewTag, dateFormat: dateTime } = formatDateString(
    inserted_at
  );

  const {
    state: { currentActivePost },
    dispatch: communityDispatch,
  } = useCommunityContext();
  const { dispatch: commentDispatch } = useCommentsContext();

  const _renderMessageBody = () => {
    const { message_parts } = post.message;
    if (message_parts.length) {
      const { type, body, link_previews } = message_parts[0];
      switch (type) {
        case 'text':
        case 'gallery':
          return <TextMessage body={body} />;
        case 'link':
          return link_previews.map((item, index) => {
            return <LinkPreview key={index} linkPreview={item} />;
          });
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    subscribe({
      channel,
      subscribers: [
        {
          action: LISTEN_NEW_MESSAGE,
          onReceived: ({ message }) => {
            communityDispatch(updateCommentsCount(post.id, 1));
            commentDispatch(addComment(message));

            // auto scroll to bottom when add new comment
            requestAnimationFrame(() => {
              const pd = document.getElementById('post-detail');
              pd.scrollTop = pd.scrollHeight;
            });
          },
        },
        {
          action: MESSAGE_DESTROY,
          onReceived: ({ message }) => {
            communityDispatch(updateCommentsCount(post.id, -1));
            commentDispatch(destroyComment(message));
          },
        },
        {
          action: MESSAGE_UPDATE,
          onReceived: ({ message }) => {
            communityDispatch(updatePost(message));
            commentDispatch(updateComment(message));
          },
        },
      ],
    });
  }, [communityDispatch, post.id, commentDispatch, channel]);

  return (
    <div
      className={ClassNames(
        `p-4 border-bottom position-relative ${styles['wrapper']} ${
          post.id === currentActivePost.id ? styles['active'] : ''
        }`
      )}
      onClick={() => onClick(post)}
    >
      <div className="d-flex align-items-center mb-2">
        <Avatar user={post.community_member} className={styles['avatar-url']} />
        <div className="ml-2">
          <strong>{fullName(community_member)}</strong>
        </div>
        <div className={styles['time']}>&nbsp;·&nbsp;{dateTime} </div>
        {showNewTag ? (
          <div className={styles['new-tag']}>
            <img src={NewTag} alt="newTag" />
          </div>
        ) : null}
      </div>

      <h3>{post.title}</h3>

      <div className={styles['text-description']}>{_renderMessageBody()}</div>

      <div className="d-flex mt-5">
        <div
          className={ClassNames(
            `flex-grow-1 d-flex ${styles['comment-reaction']}`
          )}
        >
          <div>
            <img src={MessageLined} alt={'message'} />
            <span>&nbsp;{message_count - 1}&nbsp;</span>
            <span className="d-none d-xl-inline-block">
              {pluralize(message_count - 1, 'Comment')}
            </span>
          </div>

          <div className="ml-3">
            <Reactions
              itemKey={post.id}
              channel={channel}
              message_id={message_id}
              reactions={reactions}
              onReactionUpdate={onReactionUpdate}
              showEmoji={false}
              uniqueId={`${message_id}-post`}
            />
          </div>
        </div>
        <div
          className={`flex-grow-1 text-right ${styles['space-name']}`}
          title={`#${post.topic.name}`}
        >
          #{post.topic.name}
        </div>
      </div>
    </div>
  );
}

Post.propTypes = {
  post: PropType.object.isRequired,
  onClick: PropType.func,
};

export default Post;
