import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import styles from './space-input.module.scss';

import PhoneInputElement from './forms/PhoneInputElement';

export default function SpaceInput(props) {
  const {
    placeholder,
    prefix,
    suffix,
    className,
    inputClassName,
    value,
    defaultValue,
    onChange,
    children,
    type,
    valid,
    theme,
    rows,
    style,
    suffixClassName,
    prefixClassName,
    onClick,
    autosize,
    maxRows,
    ...otherProps
  } = props;

  const validClassName = valid ? '' : styles['invalid'];
  const inputPaddingTop = 10;
  const singleRowHeight = 21;
  const minHeight = rows * singleRowHeight + inputPaddingTop * 2;

  const textAreaRef = useRef(null);

  const _onKeyUp = () => {
    if (autosize && textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      let height = Math.max(textAreaRef.current.scrollHeight, minHeight);

      if (maxRows !== null) {
        const maxHeight = singleRowHeight * maxRows + inputPaddingTop * 2;
        height = Math.min(maxHeight, height);
      }
      textAreaRef.current.style.height = `${height}px`;
    }
  };

  const _renderInput = () => {
    switch (type) {
      case 'tel':
        return (
          <PhoneInputElement
            value={value}
            className={styles['input__inner']}
            valid={valid}
            errorClassName={validClassName}
            onChange={onChange}
            placeholder={placeholder}
          />
        );
      case 'textarea':
        return (
          <textarea
            value={value}
            autoComplete="off"
            rows={rows}
            placeholder={placeholder}
            className={ClassNames(
              `${styles['textarea__inner']} ${inputClassName}`
            )}
            style={{ minHeight: `${minHeight}px` }}
            onChange={onChange}
            onKeyUp={_onKeyUp}
            ref={textAreaRef}
            {...otherProps}
          />
        );
      case 'text':
        return (
          <input
            type="text"
            {...otherProps}
            defaultValue={defaultValue}
            value={value}
            autoComplete="off"
            placeholder={placeholder}
            style={{ color: theme === 'dark' ? '#FFF' : '', ...style }}
            className={ClassNames(
              styles['input__inner'],
              validClassName,
              { 'bg-dark': theme === 'dark' },
              inputClassName
            )}
            onChange={onChange}
          />
        );
      default:
        return (
          <input
            type="text"
            {...otherProps}
            defaultValue={defaultValue}
            value={value}
            autoComplete="off"
            placeholder={placeholder}
            style={{ color: theme === 'dark' ? '#FFF' : '', ...style }}
            className={ClassNames(
              styles['input__inner'],
              validClassName,
              { 'bg-dark': theme === 'dark' },
              inputClassName
            )}
            onChange={onChange}
          />
        );
    }
  };

  return (
    <div
      className={ClassNames(
        styles['input-wrapper'],
        { [styles['input--prefix']]: !!prefix },
        { [styles['input--suffix']]: !!suffix },
        { [styles['input-group']]: !!children },
        { [styles['input-group--prepend']]: !!children },
        { 'bg-dark': theme === 'dark' },
        className
      )}
      onClick={onClick}
    >
      {children ? (
        <div className={ClassNames(styles['input-group__prepend'])}>
          {children}
        </div>
      ) : null}

      {_renderInput()}

      {prefix ? (
        <span className={ClassNames(styles['input__prefix'], prefixClassName)}>
          {prefix}
        </span>
      ) : null}

      {suffix ? (
        <span className={ClassNames(styles['input__suffix'], suffixClassName)}>
          {suffix}
        </span>
      ) : null}
    </div>
  );
}

SpaceInput.defaultProps = {
  valid: true,
  type: 'text',
  theme: 'light',
  rows: 1,
  autosize: false,
};

SpaceInput.propTypes = {
  placeholder: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  suffix: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  valid: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  style: PropTypes.object,
  suffixClassName: PropTypes.string,
  prefixClassName: PropTypes.string,
  onClick: PropTypes.func,
  autosize: PropTypes.bool,
  maxRows: PropTypes.number,
};
