export const apiDefaultHeaders = () => {
  let params = new URLSearchParams(window.location.search.slice(1));

  return {
    'Hobnob-Client': process.env.REACT_APP_HOBNOB_CLIENT_VERSION,
    superadmin: params.get('from') === 'admin' ? 'true' : undefined,
    // Use lower case `accept` can override the default `apollo-link-http` header `*/*`.
    accept: 'application/vnd.hobnob+json;version=2',
  };
};
