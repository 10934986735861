import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

export default function SpaceOption(props) {
  const { onClick, label, value, className, children } = props;

  function _onClick() {
    onClick(value, label);
  }

  return (
    <li
      className={ClassNames(
        `border-bottom d-flex align-items-center ${className}`
      )}
      onClick={_onClick}
    >
      {children}
      {label}
    </li>
  );
}

SpaceOption.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.any,
};
