import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import PostList from './PostList';
import PostDetails from './PostDetails';
import { useCommunityContext } from '../../store/community';

import PostBox from './PostBox';
import styles from './post-list.module.scss';
import TopicFilterSelection from './TopicFilterSelection';
import FloatingButtonContainer from '../FloatingButtonContainer';
import FloatingButtonSpacer from '../FloatingButtonSpacer';

function Container(props) {
  const {
    state: { showPostDetailColumn },
  } = useCommunityContext();
  const { pageInfo, community } = props;

  return (
    <div className="container-fluid flex-grow-1 d-flex flex-column">
      <div className={ClassNames(`row flex-grow-1`)}>
        {/*post list */}
        <div
          className={ClassNames(
            `col-sm-4 col-xl-6 d-sm-block ${
              showPostDetailColumn ? 'd-none' : ''
            }`
          )}
        >
          <div className={styles['wrapper']}>
            <TopicFilterSelection community={community} />
            <PostBox community={community} />
            <PostList community={community} pageInfo={pageInfo} />
            <FloatingButtonSpacer />
            <FloatingButtonContainer />
          </div>
        </div>

        {/*post details*/}
        <div
          className={ClassNames(
            `col-sm-8 col-xl-6 d-sm-flex border-left p-0 ${
              showPostDetailColumn ? 'd-flex' : 'd-none'
            }`
          )}
        >
          <PostDetails />
        </div>
      </div>
    </div>
  );
}

Container.propType = {
  pageInfo: PropTypes.string,
  community: PropTypes.object.isRequired,
};

export default Container;
