import React from 'react';

import ClassNames from 'classnames';
import styles from './details-container.module.scss';

import { fullName } from '../../helpers/user_helpers';
import { formatISOTimestamp } from '../../helpers/date_helpers';
import RecentParticipantsPreview from './RecentParticipantsPreview';
import Reactions from '../Reactions';

import CreateComment from './CreateComment';

import Avatar from '../Avatar';
import Message from './Message';
import { useCommunityContext } from '../../store/community';
import { updateSinglePostReactions } from '../../store/community/actions';
import CommentsContainer from './CommentsContainer';

function DetailsContainer() {
  const {
    state: { currentActivePost: post },
    dispatch: communityDispatch,
  } = useCommunityContext();

  if (JSON.stringify(post) === '{}') {
    return null;
  }

  const _onReactionUpdate = (post_id, reactions) => {
    communityDispatch(updateSinglePostReactions({ post_id, reactions }));
  };

  return (
    <div
      className={ClassNames(
        `w-100 d-flex flex-column flex-grow-1 ${styles['wrapper']}`
      )}
    >
      <div
        className={`border-bottom d-flex align-items-center ${
          styles['header']
        } p-3 `}
      >
        <Avatar user={post.community_member} />
        <strong className="ml-2">{fullName(post.community_member)}</strong>
      </div>

      <div id={'post-detail'} className={`flex-grow-1  ${styles['content']}`}>
        <div className={`p-4`}>
          <h2 className={styles['title']}>{post.title}</h2>
          <div className={styles['message']}>
            <div className={styles['date-time']}>
              {formatISOTimestamp(post.inserted_at)}
            </div>

            <Message source={post.message} />
            <Reactions
              itemKey={post.id}
              channel={post.channel}
              message_id={post.message_id}
              reactions={post.message.reactions}
              onReactionUpdate={_onReactionUpdate}
              showEmoji={true}
              className="pt-3"
              uniqueId={`${post.message_id}-detail`}
            />
          </div>
        </div>

        <div className="bg-light p-3 border-top border-bottom d-flex justify-content-between">
          <RecentParticipantsPreview users={post.recent_participants} />

          <span className={styles['post-name']}># {post.topic.name}</span>
        </div>
        <CommentsContainer />
      </div>

      <div className={`p-1 pt-3 pb-3 border-top bg-light ${styles['footer']}`}>
        <CreateComment post={post} />
      </div>
    </div>
  );
}

export default DetailsContainer;
