import React, { useReducer, useContext } from 'react';

import reducer, { initState } from './reducer';

const Context = React.createContext();

export const Provider = props => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useCommunityContext = () => useContext(Context);
