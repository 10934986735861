import React from 'react';

import HomePage from '../home/HomePage';
import { useLoginAccessToken } from '../../helpers/localstorage_helpers';

export default () => {
  // use login access token as the request token
  useLoginAccessToken();

  return <HomePage />;
};
