import React, { useState } from 'react';
import ClassNames from 'classnames';

import PostBoxOperationBar from './PostBoxOperationBar';
import Button from '../Button';
import styles from './create-comment.module.scss';
import SpaceInput from '../SpaceInput';
import { push } from '../../store/socket/reducer';
import { CREATE_NEW_MESSAGE } from '../../services/room_channel_types';
import { useGlobalContext } from '../../store/global';
import TokenizedJoinModal from '../modals/TokenizedJoinModal';

export default function CreateComment({ post }) {
  const { state: globalContext } = useGlobalContext();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [focus, setFocus] = useState(false);

  const _onChange = e => {
    setValue(e.target.value);
    setFocus(e.target.value !== '');
  };

  const _postComment = () => {
    setLoading(true);
    const { channel } = post;
    const payload = {
      type: 'chat',
      message_parts: [
        {
          type: 'text',
          body: value,
        },
      ],
    };

    push({
      channel,
      action: CREATE_NEW_MESSAGE,
      payload,
      successCallback: () => {
        setValue('');
        setLoading(false);

        setTimeout(() => {
          const postDetailContainer = document.getElementById('post-detail');
          const scrollHeight = postDetailContainer.scrollHeight;
          postDetailContainer.scrollTop = scrollHeight;
        }, 0);
      },
      errorCallback: () => {
        setLoading(false);
      },
    });
  };

  return (
    <>
      <div
        className={ClassNames(
          `d-flex align-items-center ${focus ? styles['not-one-line'] : ''}`
        )}
        onClick={() => setShowJoinModal(globalContext.isInvitation)}
      >
        <SpaceInput
          value={value}
          onChange={_onChange}
          suffix={<PostBoxOperationBar />}
          suffixClassName={styles['suffix']}
          inputClassName={styles['input']}
          className={styles['operate-bar']}
          placeholder={'Comment...'}
          type={'textarea'}
          autosize={true}
          maxRows={4}
        />
        <Button
          disabled={
            value.trim() === '' || loading || globalContext.isInvitation
          }
          className={styles['button']}
          onClick={_postComment}
        >
          send
        </Button>
      </div>
      {showJoinModal ? (
        <TokenizedJoinModal onClose={() => setShowJoinModal(false)} />
      ) : null}
    </>
  );
}
