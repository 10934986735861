import React from 'react';

import Button from '../Button';
import parse from 'url-parse';

import InstagramColorIcon from '../../assets/icon/instagram_color_icon.svg';
import TwitterColorIcon from '../../assets/icon/twitter_color_icon.svg';
import FacebookColorIcon from '../../assets/icon/facebook_color_icon.svg';

function SocialIcon(props) {
  const {
    social_link_facebook,
    social_link_instagram,
    social_link_twitter,
  } = props;

  if (!social_link_facebook && !social_link_instagram && !social_link_twitter) {
    return null;
  }

  const _parseLink = link => {
    const parseLink = parse(link, {});
    parseLink.set('protocol', 'https');
    return parseLink.href;
  };

  return (
    <div className="mt-3 mb-3">
      {social_link_instagram && (
        <Button
          wrapperElement="a"
          href={_parseLink(social_link_instagram)}
          target="_blank"
          className="mr-4"
        >
          <img src={InstagramColorIcon} alt="Instagram" />
        </Button>
      )}

      {social_link_twitter && (
        <Button
          wrapperElement="a"
          className="mr-4"
          href={_parseLink(social_link_twitter)}
          target="_blank"
        >
          <img src={TwitterColorIcon} alt="Twitter" />
        </Button>
      )}

      {social_link_facebook && (
        <Button
          wrapperElement="a"
          href={_parseLink(social_link_facebook)}
          target="_blank"
        >
          <img src={FacebookColorIcon} alt="Facebook" />
        </Button>
      )}
    </div>
  );
}

export default SocialIcon;
