export function pluralize(count, singularString) {
  if (count === 1) {
    return singularString;
  } else {
    switch (singularString) {
      case 'child':
        return 'children';
      default:
        return `${singularString}s`;
    }
  }
}
