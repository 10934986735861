import React from 'react';
import PropTypes from 'prop-types';

import styles from './join-modal.module.scss';
import UserLoginForm from './UserLoginForm';

export default function LoginModal(props) {
  const { onSuccess } = props;
  const customizedMessage = (
    <div className="mb-4">
      <strong>Hobnob - Make Life Happen</strong>
      <br />
      <span>Enter your name and number to login.</span>
    </div>
  );

  return (
    <UserLoginForm
      {...props}
      onLoginSuccess={onSuccess}
      customizedMessage={customizedMessage}
    >
      <div className={styles['avatar-wrapper']} />
      <h1>Log In</h1>
    </UserLoginForm>
  );
}

LoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
