import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import { ApolloClient } from 'apollo-client';
import Link from './apollo-links/Link';
import Cache from './apollo-links/Cache';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import ClientCredentialLoader from './components/ClientCredentialLoader';
import WebAppRouter from './router';
import Honeybadger from 'honeybadger-js';
import ErrorBoundary from '@honeybadger-io/react';
import ErrorPage from './pages/error/ErrorPage';
// import { Provider } from 'react-redux';
// import socketStore from './store/socket';

const client = new ApolloClient({
  link: Link,
  cache: Cache,
});

let app = (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      {/* <Provider store={socketStore}> */}
      <ClientCredentialLoader>
        <WebAppRouter />
      </ClientCredentialLoader>
      {/* </Provider> */}
    </ApolloHooksProvider>
  </ApolloProvider>
);

// Honeybadger configuration
const environment = process.env.REACT_APP_ENV;
if (environment === 'staging' || environment === 'production') {
  const honeybadger = Honeybadger.configure({
    api_key: process.env.REACT_APP_HONEYBADGER_API_KEY,
    environment: process.env.REACT_APP_ENV,
  });

  app = (
    <ErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPage}>
      {app}
    </ErrorBoundary>
  );
}

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
