import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import HobnobModal from '../HobnobModal';
import styles from './anonymous-modal.module.scss';
import Button from '../Button';
import { useCommunityContext } from '../../store/community';
import SpaceAvatar from '../SpaceAvatar';
import JoinModal from './JoinModal';
import LoginModal from './LoginModal';

export default function AnonymousModal(props) {
  const { show, onClose, onSuccess } = props;

  const [showAnonymousModal, setShowAnonymousModal] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);

  const {
    state: { community: publicCommunity },
  } = useCommunityContext();

  const containerStyles = {
    minHeight: 'auto',
  };

  const _onLogin = () => {
    setShowAnonymousModal(false);
    setShowLoginModal(true);
    setShowJoinModal(false);
  };

  const _onJoin = () => {
    setShowAnonymousModal(false);
    setShowLoginModal(false);
    setShowJoinModal(true);
  };

  const _renderAnonymousLayout = () => {
    return (
      <>
        <SpaceAvatar
          space={publicCommunity}
          hasTooltip={false}
          className={styles['avatar-wrapper']}
          size={'big'}
        />
        <h1>
          Welcome to
          <br />
          {publicCommunity.name}
        </h1>
        <div className={ClassNames(`mb-4 ${styles['description']}`)}>
          {publicCommunity.description}
        </div>

        <Button className="mb-4" onClick={_onJoin}>
          Join now
        </Button>
        <div className={styles['text-prompt']}>
          Already a member?
          <Button
            type={'text'}
            className="text-decoration-none"
            onClick={_onLogin}
          >
            &nbsp;Log in
          </Button>
        </div>
      </>
    );
  };

  const _renderComponent = () => {
    if (showAnonymousModal) {
      return _renderAnonymousLayout();
    }
    if (showLoginModal) {
      return (
        <LoginModal
          show={showLoginModal}
          modal={false}
          onClose={() => {}}
          onSuccess={onSuccess}
        />
      );
    }

    if (showJoinModal) {
      return (
        <JoinModal
          show={showJoinModal}
          modal={false}
          onClose={() => {}}
          onSuccess={onSuccess}
        />
      );
    }

    return _renderAnonymousLayout();
  };

  const _onClose = () => {
    if (showAnonymousModal) {
      onClose();
    }
    if (showLoginModal) {
      setShowAnonymousModal(true);
      setShowLoginModal(false);
      setShowJoinModal(false);
    }

    if (showJoinModal) {
      setShowAnonymousModal(true);
      setShowLoginModal(false);
      setShowJoinModal(false);
    }
  };

  return (
    <HobnobModal
      show={show}
      onClose={_onClose}
      containerStyles={containerStyles}
      showCancelIcon={showLoginModal || showJoinModal}
      contentClassName={styles['wrapper']}
    >
      {_renderComponent()}
    </HobnobModal>
  );
}

AnonymousModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  onJoin: PropTypes.func,
  onSuccess: PropTypes.func,
};
