import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './link-preview.module.scss';

export default function LinkPreview({ linkPreview }) {
  const { description, title_link, image_url } = linkPreview;
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    setImageUrl(image_url);
  }, [image_url]);

  const _onClick = () => {
    window.open(title_link);
  };

  const _onImageError = () => {
    setImageUrl(null);
  };

  return (
    <div className={styles['link-container']} onClick={_onClick}>
      {imageUrl && (
        <div className={styles['image-wrapper']}>
          <img
            onError={_onImageError}
            src={imageUrl}
            alt="link"
            className={styles['link-image']}
          />
        </div>
      )}

      <div className={styles['link-service-wrapper']}>
        <div className={styles['link-description']} title={description}>
          {description}
        </div>
        <div className={styles['service-link']}>{title_link}</div>
      </div>
    </div>
  );
}

LinkPreview.propTypes = {
  linkPreview: PropTypes.object.isRequired,
};
