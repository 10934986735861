import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Reactions from '../Reactions';

import { fullName } from '../../helpers/user_helpers';
import { formatUnixTime } from '../../helpers/date_helpers';
import styles from './comment.module.scss';

import Avatar from '../Avatar';
import Message from './Message';
import { useCommunityContext } from '../../store/community';

function Comment({ comment }) {
  const { timestamp, reactions } = comment;
  const [commentReactions, setCommentReactions] = useState([]);

  const {
    state: { community, currentActivePost: post },
  } = useCommunityContext();
  const { community_members } = community;

  useEffect(() => {
    setCommentReactions(reactions);
  }, [reactions]);

  const user = community_members.filter(
    item => item.user_id === comment.user_id
  );

  const _onReactionUpdate = (comment_id, reactions) => {
    setCommentReactions(reactions);
  };

  return (
    <div className={ClassNames(`${styles['wrapper']}`)} id={comment.id}>
      <Avatar className={styles['avatar']} user={user[0]} />
      <div className={ClassNames(`ml-2 ${styles['container']}`)}>
        <strong>{fullName(user[0])}</strong>
        <span className={ClassNames(styles['date-time'], 'ml-2')}>
          {formatUnixTime(timestamp)}
        </span>
        <Message source={comment} />
        <Reactions
          itemKey={comment.id}
          channel={post.channel}
          message_id={comment.id}
          reactions={commentReactions}
          onReactionUpdate={_onReactionUpdate}
          showEmoji={true}
          uniqueId={comment.id}
        />
        {comment.sub_threads &&
          comment.sub_threads.map(item => {
            return <Comment comment={item} key={item.id} />;
          })}
      </div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default Comment;
