import React from 'react';
import Proptypes from 'prop-types';
import SpaceInput from '../SpaceInput';
import ClassNames from 'classnames';
import styles from './confirmation-form.module.scss';
import Button from '../Button';

export default function ConfirmationForm(props) {
  const {
    onConfirm,
    fields,
    updateForm,
    onResendCode,
    loading,
    isError,
  } = props;

  function _onSubmit(e) {
    e.preventDefault();
  }

  function _setConfirmationCode(e) {
    const value = e.target.value.replace(/[^\d]/g, '');
    updateForm({ confirmationCode: value });
  }

  function _validateConfirmationCode() {
    const { confirmationCode } = fields;

    return confirmationCode.length === 6;
  }

  return (
    <form onSubmit={_onSubmit}>
      <SpaceInput
        placeholder={'Enter your six digit code'}
        maxLength="6"
        valid={!isError}
        value={fields.confirmationCode}
        onChange={_setConfirmationCode}
        className={ClassNames(`flex-grow-1 mb-3 ${styles['input']}`)}
      />
      <Button
        disabled={!_validateConfirmationCode()}
        className={ClassNames(`${styles['button']} mb-3`)}
        loading={loading}
        onClick={onConfirm}
      >
        confirm
      </Button>
      <Button
        type="text"
        className="text-decoration-none"
        onClick={onResendCode}
      >
        Resend Code
      </Button>
    </form>
  );
}

ConfirmationForm.propTypes = {
  onConfirm: Proptypes.func,
  fields: Proptypes.object.isRequired,
  updateForm: Proptypes.func.isRequired,
  onResendCode: Proptypes.func.isRequired,
  loading: Proptypes.bool,
};
