import { setContext } from 'apollo-link-context';

/**
 * Set the `authorization` in header if the token is not empty.
 *
 * Refer to: https://www.apollographql.com/docs/react/recipes/authentication/
 */
export const CLIENT_ACCESS_TOKEN = 'client_access_token';
export const USER_ACCESS_TOKEN = 'user_access_token';
export const USER_REFRESH_TOKEN = 'user_refresh_token';
export const INVITATION_ACCESS_TOKEN = 'invitation_access_token';
export const ACCESS_TOKEN = 'access_token';

const FORCE_USING_CLIENT_CREDENTIAL_QUERIES = [
  'CommunityBySlug',
  'InvitationByToken',
  'InvitationAuth',
];

const useClientCredential = operationName => {
  return FORCE_USING_CLIENT_CREDENTIAL_QUERIES.indexOf(operationName) > -1;
};

const AuthLink = setContext((operation, { headers }) => {
  const { operationName } = operation;

  // use the invitation/user credential as default
  let token = localStorage.getItem(ACCESS_TOKEN);
  if (!token || useClientCredential(operationName)) {
    token = localStorage.getItem(CLIENT_ACCESS_TOKEN);
  }

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
});

export default AuthLink;
