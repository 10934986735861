import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'classnames';

import styles from './button.module.scss';
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';

function _buttonClass(disabled, filled, type, wrapperElement) {
  if (type === 'text' || wrapperElement === 'a') {
    return styles['button-text'];
  }

  if (filled) {
    return styles['button-filled'];
  }

  return styles['button-outline'];
}

const Button = props => {
  const {
    className,
    children,
    href,
    filled,
    wrapperElement,
    disabled,
    type,
    loading,
    ...otherProps
  } = props;

  const disabledButton = disabled ? styles['is-disabled'] : '';

  const buttonClass = _buttonClass(disabled, filled, type, wrapperElement);

  let component = children;

  if (loading) {
    component = (
      <div>
        <LoadingIcon
          className={ClassNames(`indicator ${styles['loading-icon']}`)}
        />{' '}
        loading
      </div>
    );
  }

  return React.createElement(
    wrapperElement,
    {
      className: ClassNames(
        buttonClass,
        className,
        disabledButton,
        loading ? styles['is-loading'] : ''
      ),
      disabled: disabled,
      href: href,
      ...otherProps,
    },
    component
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  href: PropTypes.string,
  filled: PropTypes.bool,
  wrapperElement: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  filled: true,
  wrapperElement: 'button',
  disabled: false,
  type: 'button',
  loading: false,
};

export default Button;
