import React from 'react';
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';
import ClassNames from 'classnames';

import styles from './loading-indicator.module.scss';

export default function LoadingIndicator() {
  return (
    <div className={styles['loading-wrapper']}>
      <LoadingIcon
        className={ClassNames(`indicator ${styles['loading-icon']}`)}
      />
    </div>
  );
}
