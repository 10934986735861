import { HIDE_HUD, SHOW_HUD } from './actionType';

export const hudInit = {
  showErrorModal: false,
};

const reducer = (state = hudInit, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_HUD:
      return { ...state, showErrorModal: true };
    case HIDE_HUD:
      return { ...state, showErrorModal: false };
    default:
      return state;
  }
};

export default reducer;
