import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import Switcher from './Switcher';
import Introduction from './Introduction';

function Sidebar(props) {
  const { onClick, community } = props;
  return (
    <div className={ClassNames(`container-fluid d-flex flex-column`)}>
      <div className={ClassNames(`row flex-grow-1`)}>
        <Switcher />
        <Introduction community={community} onClick={onClick} />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  onClick: PropTypes.func,
  community: PropTypes.object,
};

export default Sidebar;
