import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import styles from './introduction.module.scss';
import TopicFilterContainer from './TopicFilterContainer';
import DownloadBar from './DownloadBar';
import SocialIcons from './SocialIcons';
import Members from './Members';
import Description from './Description';
import SpaceCoverImage from './SpaceCoverImage';

import MenuWhiteIcon from '../../assets/icon/Menu_white_icon.svg';
import SpaceAvatar from '../SpaceAvatar';

function Introduction(props) {
  const { onClick, community } = props;
  const { name, cover_image_url } = community;

  return (
    <div className={styles['wrapper']}>
      <img
        className={ClassNames(`d-block d-lg-none ${styles['menu-write-icon']}`)}
        src={MenuWhiteIcon}
        onClick={onClick}
        alt="Introduction"
      />

      <SpaceCoverImage cover_image_url={cover_image_url} />

      {/*space avatar*/}
      <SpaceAvatar
        space={community}
        className={ClassNames(`${styles['space-avatar']} ml-4 mr-4`)}
        hasTooltip={false}
        size={'big'}
      />

      <div className={styles['center-wrapper']}>
        <div className="pl-4 pr-4 pb-5 border-bottom">
          <h2>{name}</h2>

          {community.description ? (
            <Description description={community.description} />
          ) : null}

          <SocialIcons
            social_link_facebook={community.social_link_facebook}
            social_link_instagram={community.social_link_instagram}
            social_link_twitter={community.social_link_twitter}
          />

          <Members />
        </div>

        <TopicFilterContainer
          topics={community.topics}
          toggleSideBar={onClick}
        />
      </div>

      <DownloadBar />
    </div>
  );
}

Introduction.propTypes = {
  onClick: PropTypes.func,
  community: PropTypes.object,
};

export default Introduction;
