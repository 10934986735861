import React from 'react';

import styles from './join-modal.module.scss';
import { useCommunityContext } from '../../store/community';
import UserLoginForm from './UserLoginForm';
import { useMutation } from 'react-apollo-hooks';
import { JOIN_COMMUNITY } from '../../graphql/Community';
import { showHud } from '../../store/hud/actions';
import { useHudContext } from '../../store/hud';
import { setLoginTokens } from '../../helpers/localstorage_helpers';
import SpaceAvatar from '../SpaceAvatar';

export default function JoinModal(props) {
  const { onSuccess } = props;

  const { dispatch: hudDispatch } = useHudContext();
  const {
    state: { community },
  } = useCommunityContext();

  // join community mutation
  const [joinCommunity] = useMutation(JOIN_COMMUNITY);

  // once the login success, then process the join space logic
  const onLoginSuccess = user_auth => {
    setLoginTokens(user_auth);
    const { id: communityId } = community;
    joinCommunity({ variables: { communityId } }).then(
      () => {
        onSuccess(user_auth);
      },
      error => {
        const {
          networkError: { statusCode },
        } = error;
        if (statusCode && statusCode === 422) {
          onSuccess(user_auth);
        } else {
          hudDispatch(showHud());
        }
      }
    );
  };

  const customizedMessage = (
    <div className="mb-4">
      To <strong>create posts or comment</strong> you must be a
      <br />
      member. Enter your name & number to join.
    </div>
  );

  return (
    <UserLoginForm
      {...props}
      onLoginSuccess={onLoginSuccess}
      customizedMessage={customizedMessage}
    >
      <div className={styles['avatar-wrapper']}>
        <SpaceAvatar
          space={community}
          hasTooltip={false}
          className={styles['avatar-icon']}
        />
      </div>
      <h1>Join {community.name}</h1>
    </UserLoginForm>
  );
}
