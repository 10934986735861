import { createHttpLink } from 'apollo-link-http';
import { apiDefaultHeaders } from '../services/request_header_service';

/**
 * Setup the request link, it will be used for GraphQL request base URL.
 *
 * Refer to: https://www.apollographql.com/docs/link/links/http/
 */

const HttpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL || '',
  headers: apiDefaultHeaders(),
  fetchOptions: () => {},
});

export default HttpLink;
