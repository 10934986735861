import moment from 'moment';

/**
 *
 * @param time： post written time
 * @returns {string}
 */

const DATE_FORMAT = 'MMMM D, YYYY';
const TIME_FORMAT = 'h:mm a';
const FULL_DATE_TIME_FORMAT = `${DATE_FORMAT} [at] ${TIME_FORMAT}`;

export function formatDateString(time) {
  const m1 = moment(time),
    m2 = moment(),
    difference = moment.duration(m2 - m1, 'ms').asMinutes();

  const fromNow = moment(time)
    .local()
    .fromNow();

  const list = fromNow.split(' ');

  let string = list[0];
  if (list[0] === 'a' || list[0] === 'an') {
    string = '1';
  }

  string = string + list[1].substring(0, 1);

  return {
    writtenNow: difference < 120,
    dateFormat: difference < 1 ? 'now' : string,
  };
}

export function formatISOTimestamp(time) {
  return moment(time)
    .local()
    .format(FULL_DATE_TIME_FORMAT);
}

export function formatUnixTime(time) {
  return moment
    .unix(time)
    .local()
    .format(TIME_FORMAT);
}

export function formatUnixDate(timestamp) {
  return moment.unix(timestamp).format(DATE_FORMAT);
}

export function formatUnixTimestamp(timestamp) {
  return moment.unix(timestamp).format(FULL_DATE_TIME_FORMAT);
}