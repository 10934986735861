import {
  APPEND_POSTS,
  CREATE_POSTS,
  REQUEST_COMMUNITY,
  SET_CURRENT_ACTIVE_POST,
  SET_SHOW_POST_DETAIL_COLUMN,
  SET_TOPIC_IDS,
  UNSHIFT_POST,
  UPDATE_SINGLE_POST_REACTIONS,
  UPDATE_COMMENTS_COUNT,
  MARK_AS_READ,
  UPDATE_POST,
} from './actionType';
import _ from 'lodash';
import { filterDestroyedPosts } from '../../helpers/community_helpers';

export const initState = {
  community: {},
  topicIds: [],
  posts: [],
  currentActivePost: {},
  currentActivePostTimestamp: null,
  showPostDetailColumn: false,
};

const _getActivePost = posts => {
  if (posts && posts.length > 0) {
    return posts[0];
  }

  return {};
};

const _updatePosts = (state, newPost) => {
  const { posts } = state;
  return posts.map(post => {
    if (post.message.id === newPost.id) {
      post.message = newPost;
    }

    return post;
  });
};

const _replaceActivePost = (state, newPost) => {
  const { currentActivePost } = state;
  if (currentActivePost.message.id === newPost.id) {
    currentActivePost.message = newPost;
  }

  return currentActivePost;
};

const reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case REQUEST_COMMUNITY:
      return { ...state, community: action.params };
    case SET_TOPIC_IDS:
      return { ...state, topicIds: action.params, posts: [] };
    case APPEND_POSTS:
      const { posts, currentActivePost: activePost } = state;
      const newPosts = filterDestroyedPosts(
        _.uniqBy(posts.concat(action.params), 'id')
      );
      return {
        ...state,
        posts: newPosts,
        currentActivePost: activePost || _getActivePost(newPosts),
      };
    case CREATE_POSTS:
      const initialPosts = filterDestroyedPosts(action.params);
      return {
        ...state,
        posts: initialPosts,
        currentActivePost: _getActivePost(initialPosts),
      };
    case SET_CURRENT_ACTIVE_POST:
      return {
        ...state,
        currentActivePost: action.params,
        currentActivePostTimestamp: +new Date(),
      };
    case SET_SHOW_POST_DETAIL_COLUMN:
      return { ...state, showPostDetailColumn: action.params };
    case UPDATE_SINGLE_POST_REACTIONS:
      const { posts: existingPosts } = state;
      const { post_id, reactions } = action.params;

      const updatedPosts = existingPosts.map(post => {
        if (post.id === post_id) {
          post.message.reactions = reactions;
        }
        return post;
      });

      return { ...state, posts: updatedPosts };
    case UNSHIFT_POST:
      return { ...state, posts: _.concat([action.params], state.posts) };
    case UPDATE_COMMENTS_COUNT:
      const { posts: originPosts } = state;
      const { post_id: updatedPostId, count } = action.params;

      const updateCommentsCountPosts = originPosts.map(post => {
        if (post.id === updatedPostId) {
          post.message_count = post.message_count + count;
        }
        return post;
      });
      return { ...state, posts: updateCommentsCountPosts };
    case MARK_AS_READ:
      const { lastReadAt } = action.params;
      const { posts: allPosts, currentActivePost } = state;
      const updatedIsReadPosts = allPosts.map(post => {
        if (post.id === currentActivePost.id) {
          post.last_read_at = lastReadAt;
        }
        return post;
      });

      return {
        ...state,
        posts: updatedIsReadPosts,
      };
    case UPDATE_POST:
      const { newPost } = action.params;
      return {
        ...state,
        posts: _updatePosts(state, newPost),
        currentActivePost: _replaceActivePost(state, newPost),
      };
    default:
      return state;
  }
};

export default reducer;
