import React from 'react';
import styles from './ErrorPage.module.scss';
import ClassNames from 'classnames';
import error from '../../assets/500.png';

const ErrorPage = () => {
  return (
    <div className={styles['ErrorPage']}>
      <div className={ClassNames(styles['container'], 'bg-dark')}>
        <p className={styles['error-code']}>500</p>
        <img src={error} alt="error" />
        <p>
          An error occurred with your request, <br />
          Please try again later.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
