import { HIDE_HUD, SHOW_HUD } from './actionType';

export function showHud() {
  return {
    type: SHOW_HUD,
  };
}

export function hideHud() {
  return {
    type: HIDE_HUD,
  };
}
