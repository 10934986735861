import React from 'react';
import styles from './space-avatar.module.scss';
import ClassNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { getSpaceIconBackground } from '../helpers/community_helpers';
import GraphemeSplitter from 'grapheme-splitter';

// all emojis unicode pattern: https://www.regextester.com/106421
const SIZE_CONFIG = {
  small: {
    normalFontSize: 12,
    smallFontSize: 10,
  },
  big: {
    normalFontSize: 18,
    smallFontSize: 16,
  },
};

const SpaceAvatar = ({
  space,
  communityId,
  className,
  size = 'small',
  hasTooltip = true,
}) => {
  const { id, name, slug, community_initials, my_community_member } = space;

  // background color
  const backgroundColor = getSpaceIconBackground(space);

  // font size
  const fontSizeConfig = SIZE_CONFIG[size];
  let fontSize = `${fontSizeConfig.normalFontSize}px`;
  if (community_initials.length >= 3) {
    fontSize = `${fontSizeConfig.smallFontSize}px`;
  }

  // initials
  // replace emoji with custom css to solve the content is not centralized issue.
  // refer to: http://crocodillon.com/blog/parsing-emoji-unicode-in-javascript
  const _replaceEmoji = initials => {
    const splitter = new GraphemeSplitter();
    const result = splitter.splitGraphemes(initials).map(part => {
      if (part.length === 1) {
        return part;
      } else {
        return (
          <span style={{ width: fontSize, textAlign: 'center' }} key={part}>
            {part}
          </span>
        );
      }
    });

    return result;
  };
  const _getDisplayInitials = () => {
    if (my_community_member) {
      const { community_initials: my_community_initials } = my_community_member;

      return _replaceEmoji(my_community_initials || community_initials);
    }

    return _replaceEmoji(community_initials);
  };
  const displayInitials = _getDisplayInitials();

  // space icon opacity
  const _getSpaceIconOpacity = () => {
    if (id === communityId) {
      return '';
    }

    return styles['img-opacity'];
  };

  const _onClick = () => {
    window.location.href = `/spaces/${slug}`;
  };

  if (hasTooltip) {
    return (
      <>
        <div
          data-for={id}
          data-tip={name}
          key={id}
          className={ClassNames(
            `${styles['space-avatar']} ${_getSpaceIconOpacity()}`
          )}
          style={{ backgroundColor: backgroundColor, fontSize: fontSize }}
          onClick={_onClick}
        >
          {displayInitials}
        </div>

        <ReactTooltip
          id={id}
          effect={'solid'}
          className={ClassNames(styles['tooltip'], 'd-none d-sm-block')}
          scrollHide={true}
          eventOff={'scroll'}
          globalEventOf={'scroll'}
        />
      </>
    );
  } else {
    return (
      <div
        key={id}
        className={className}
        style={{ backgroundColor: backgroundColor, fontSize: fontSize }}
      >
        {displayInitials}
      </div>
    );
  }
};

export default SpaceAvatar;
