import PropTypes from 'prop-types';
import React from 'react';

export default function AvatarBorder(props) {
  const { appUser, borderWidth, includeBorder, children, size } = props;
  if (includeBorder) {
    const margin = {
      top: borderWidth / 2,
      right: borderWidth / 2,
      bottom: borderWidth / 2,
      left: borderWidth / 2,
    };
    const fullSize = size + margin.left + margin.right + borderWidth * 2;
    const width = fullSize - margin.left - margin.right;
    const height = fullSize - margin.top - margin.bottom;

    const stroke = appUser ? 'url(#hobnob-gradient)' : '#E5E5E5';

    const style = {
      height: `${fullSize}px`,
      width: `${fullSize}px`,
      textAlign: 'initial',
    };

    return (
      <React.Fragment>
        <svg style={style}>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            <circle
              cx={width / 2}
              cy={height / 2}
              r={width / 2}
              stroke={stroke}
              strokeWidth={borderWidth}
              fill={stroke}
            />
            <foreignObject
              x={borderWidth}
              y={borderWidth}
              width={width}
              height={height}
            >
              {children}
            </foreignObject>
          </g>
        </svg>
        <svg style={{ visibility: 'hidden', height: 0, width: 0 }}>
          <defs>
            <linearGradient id="hobnob-gradient" x1="100%" y1="100%" x2="0%">
              <stop stopColor="#55EFCB" />
              <stop offset="1" stopColor="#99E09C" />
            </linearGradient>
          </defs>
        </svg>
      </React.Fragment>
    );
  } else {
    return children;
  }
}

AvatarBorder.propTypes = {
  appUser: PropTypes.bool,
  borderWidth: PropTypes.number,
  children: PropTypes.any,
  includeBorder: PropTypes.bool,
  size: PropTypes.number,
};
