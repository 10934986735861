import React from 'react';
import { useGlobalContext } from '../../store/global';
import { setLoginTokens } from '../../helpers/localstorage_helpers';
import { updateGlobalContext } from '../../store/global/actions';
import JoinModal from './JoinModal';

const TokenizedJoinModal = ({ onClose }) => {
  const { dispatch: globalDispatch } = useGlobalContext();

  function _onClose() {
    onClose();
  }

  const _loginSuccess = ({ access_token, refresh_token }) => {
    setLoginTokens({ access_token, refresh_token });
    globalDispatch(
      updateGlobalContext({
        switch_to_normal_mode: true,
      })
    );
  };

  return (
    <JoinModal
      show={true}
      onClose={() => _onClose()}
      onSuccess={_loginSuccess}
    />
  );
};

export default TokenizedJoinModal;
