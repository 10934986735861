import React, { useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './space-carousel.module.scss';
import Trash from '../assets/icon/trash_icon.svg';

import ImageModal from './modals/ImageModal';

export default function SpaceCarousel(props) {
  const { dataSource = [], remove } = props;
  const displayImages = dataSource.filter(item => !!item);

  const settings = {
    dots: true,
    dotsClass: styles['slick-indicators'],
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 872,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState('');

  function _onClockImg(e, url) {
    setShowImageModal(true);
    setImageModalUrl(url);
  }

  return (
    <div className={styles['slider-wrapper']}>
      {displayImages.length === 1 ? (
        <img
          alt="gallery"
          className={ClassNames(`mw-100 rounded ${styles['single-image']}`)}
          src={displayImages[0].image_upload.medium_url}
          onClick={e =>
            _onClockImg(e, displayImages[0].image_upload.medium_url)
          }
        />
      ) : (
        <Slider {...settings}>
          {displayImages.map(item => {
            return (
              <div className={styles['image-wrapper']} key={item.id}>
                <div
                  className={styles['medium']}
                  style={{
                    backgroundImage: `url(${item.image_upload.medium_url})`,
                  }}
                  onClick={e => _onClockImg(e, item.image_upload.medium_url)}
                />

                {remove ? (
                  <img
                    src={Trash}
                    className={styles['trash-icon']}
                    alt="trash-icon"
                  />
                ) : null}
              </div>
            );
          })}
        </Slider>
      )}

      <ImageModal
        show={showImageModal}
        onClose={() => setShowImageModal(false)}
        imageModalUrl={imageModalUrl}
      />
    </div>
  );
}

SpaceCarousel.defaultProps = {
  remove: false,
};

SpaceCarousel.propTypes = {
  dataSource: PropTypes.array,
  remove: PropTypes.bool,
};
