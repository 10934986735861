import React, { useContext, useEffect, useRef, useState } from 'react';
import ClassNames from 'classnames';
import PropsTyeps from 'prop-types';

import Expand from '../../assets/icon/expand.svg';

import Button from '../Button';
import PostBoxEditor from './PostBoxEditor';
import styles from './post-box-expanded.module.scss';
import PostBoxFullModal from './PostBoxFullModal';
import { useCommunityContext } from '../../store/community';

import { CREATE_POST } from '../../graphql/Community';
import { useMutation } from 'react-apollo-hooks';
import { showHud } from '../../store/hud/actions';
import Hud from '../../store/hud';
import { sortTopics } from '../../helpers/community_helpers';
import { useWindowSize } from '../../hooks/WindowHooks';

import {
  setCurrentActivePost,
  unshiftPost,
} from '../../store/community/actions';
import SpaceSelect from '../SpaceSelect';
import UnsavedChangesModal from '../modals/UnsavedChangesModal';

const _getFirstTopicId = ({ topics }) => {
  if (topics && topics.length > 0) {
    return topics[0].id;
  }
  return '';
};

export default function PostBoxExpanded({ community }) {
  const { dispatch: hudDispatch } = useContext(Hud.Context);
  const { dispatch: communityDispatch } = useCommunityContext();

  const defaultNewPost = {
    topicId: _getFirstTopicId(community),
    title: '',
    message: {
      message_parts: [
        {
          type: 'text',
          body: '',
        },
      ],
    },
  };

  const [createPost] = useMutation(CREATE_POST);

  const [showModal, setShowModal] = useState(false);

  const [newPost, setNewPost] = useState(defaultNewPost);
  const [loading, setLoading] = useState(false);

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const selectorBoxRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(270);
  const [iconBoxStyle, setIconBoxStyle] = useState({ marginLeft: '1rem' });
  const width = useWindowSize().width;

  useEffect(() => {
    const oneLineBoxHeight = 69;
    if (
      selectorBoxRef.current &&
      selectorBoxRef.current.clientHeight &&
      selectorBoxRef.current.clientHeight <= oneLineBoxHeight
    ) {
      setMaxWidth(270);
      setIconBoxStyle({ marginLeft: '1rem' });
    } else {
      setMaxWidth('none');
      setIconBoxStyle({ marginLeft: 0 });
    }
  }, [width]);

  const onClickPostCell = value => {
    setNewPost({
      ...newPost,
      topicId: value,
    });
  };

  const _topicsOptions = () => {
    const fullTopics = community.topics
      .filter(source => !!source.name)
      .sort(sortTopics);
    return fullTopics.map(topic => {
      const { id, name } = topic;
      return {
        value: id,
        text: `# ${name}`,
      };
    });
  };

  const _updatePost = params => {
    setNewPost({
      ...newPost,
      ...params,
    });
  };

  const _createdNewPost = () => {
    const variables = {
      topicId: newPost.topicId,
      title: newPost.title,
      message: JSON.stringify(newPost.message),
    };
    setLoading(true);
    return createPost({ variables: variables }).then(
      res => {
        const {
          data: {
            create_community_post: { result },
          },
        } = res;
        communityDispatch(unshiftPost(result));
        communityDispatch(setCurrentActivePost(result));
        setShowModal(false);
        //reset post data
        setNewPost({ ...defaultNewPost, topicId: newPost.topicId });
        setLoading(false);
      },
      error => {
        if (error) {
          const { networkError } = error;
          if (networkError) {
            hudDispatch(showHud());
          }
        }
        setLoading(false);
      }
    );
  };

  const _cancelPost = () => {
    //reset post data
    setNewPost({ ...defaultNewPost, topicId: newPost.topicId });
    setShowModal(false);
  };

  const _sureClose = () => {
    setShowUnsavedChangesModal(false);

    _cancelPost();
  };

  const options = _topicsOptions();
  return (
    <div className={styles['wrapper']}>
      <div className={styles['box']}>
        <PostBoxEditor newPost={newPost} updatePost={_updatePost} />
      </div>

      <div
        ref={selectorBoxRef}
        className="d-flex justify-content-between align-items-center pt-3 flex-wrap justify-content-between"
      >
        <SpaceSelect
          theme={'dark'}
          value={newPost.topicId}
          onChange={onClickPostCell}
          options={options}
          className={ClassNames(`mt-2 mb-2 ${styles['selector-box']}`)}
          style={{ maxWidth: maxWidth }}
        />

        <div
          className={ClassNames(
            `d-flex justify-content-between align-items-center flex-grow-1 ${
              styles['icon-box']
            }`
          )}
          style={iconBoxStyle}
        >
          <img
            className={ClassNames(`${styles['icon']}`)}
            src={Expand}
            alt={'expand'}
            onClick={() => setShowModal(true)}
          />
          <Button
            disabled={!newPost.title || newPost.title === ''}
            onClick={_createdNewPost}
            loading={loading}
            className={styles['button']}
          >
            Post
          </Button>
        </div>
      </div>

      <PostBoxFullModal
        updatePost={_updatePost}
        newPost={newPost}
        show={showModal}
        onClose={() => setShowModal(false)}
        createdNewPost={_createdNewPost}
        showUnsavedChangesModal={() => setShowUnsavedChangesModal(true)}
        options={options}
        onChange={onClickPostCell}
      />

      <UnsavedChangesModal
        show={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        sureClose={_sureClose}
      />
    </div>
  );
}

PostBoxExpanded.propTypes = {
  community: PropsTyeps.object,
};
